import React, { useState, useEffect } from 'react';
import s from "./Detalle.module.css";
import { useSelector, useDispatch, connect } from 'react-redux';
import { getLibro, clearLibro, getSanteria, clearSanteria, updateCart } from '../../redux/actions';
import logo from "../../assets/agape logo rotado.png";
import { CallToAction, CallToActionOutline } from "../../components/CallToAction/CallToAction";
import { Add, Remove } from '@mui/icons-material';
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import SwiperCards from "../../components/SwiperCards/SwiperCards";
import { InfoCompras } from "../../components/InfoCompras/InfoCompras";
import nolibro from "../../assets/nolibro.png";
import { toastSuccess } from '../../helpers';
import { ArrowUpward } from '@mui/icons-material';
import Modal from 'react-modal';
import Cart from '../../components/Cart/Cart';
import useMediaQuery from '../../hooks/useMediaQuery';
import { useLocation } from 'react-router-dom';
import { trackPage, correctEncoding, capitalizeWords } from '../../helpers';

Modal.setAppElement(document.getElementById('root'));

const modalStyles = {
    overlay: {
        zIndex: 100,
        background: "#00000060",
        height: "100vh",
        width: "120vw",
        marginLeft: "-20vw"
    }
};

function Detalle() {

    const [selected, setSelected] = useState("Informacion");  // Nos dice que <a/> esta seleccionado para agregar estilos. Los <a/> son "Informacion", "Mas obras del autor", etc.
    const [unidades, setUnidades] = useState(1);  // Cantidad de unidades para agregar al carrito seleccionadas.
    const [modalCart, setModalCart] = useState(false);
    const [readMoreText, setReadMoreText] = useState(false);

    const isMobile = useMediaQuery('(max-width: 768px)');
    const topOfPageElement = document.getElementById("top-of-page-mobile");

    const location = useLocation();
    const isSanteria = location.pathname.includes("/Detalle/Objetos");
    const dispatch = useDispatch();
    const product = useSelector((state) => { // Subscripcion al estado global de libro o santeria
        return isSanteria ? state.santeria : state.libro;
    });  
    const modalControl = function(bool){
        setModalCart(bool);
    }

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(()=>{

        // Scroll al tope de la pagina
        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        
        if(topOfPageElement) {
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        // Traemos el id del product con el url y despachamos la accion de redux getLibro(id_product)
        let id_product = window.location.href.split("/");
        if(id_product[id_product.length - 2] === "Objetos") {
            id_product = id_product[id_product.length - 1];
            dispatch(getSanteria(id_product));
        } else {
            id_product = id_product[id_product.length - 1];
            dispatch(getLibro(id_product));
        }

    },[dispatch, location.pathname, topOfPageElement]);

    useEffect(() => () => {
        if(isSanteria) {
            dispatch(clearSanteria());
        } else {
            dispatch(clearLibro());
        }
    },[]); // Component did unmount que llama a la accion para borrar la informacion.

    const handleNav = function(e){  // Funcion llamada al clickear los <a/>. Selecciona para aplicar estilos especificos y scrollea.
        let redirect = e.target.getAttribute("redirect");
        setSelected(redirect);

        if(redirect === "Informacion"){
            window.scrollTo(0, document.getElementById(redirect).offsetTop - 205);
        } else {
            window.scrollTo(0, document.getElementById(redirect).offsetTop);
        }

    }

    const handleUnidades = function(el){  // Maneja el input de unidades
        if (el.value !== "") {
            if (parseInt(el.value) < parseInt(el.min)) {
                el.value = el.min;
            }
            if (parseInt(el.value) > parseInt(el.max)) {
                el.value = el.max;
            }
        }
        
        setUnidades(el.value);
    }

    const handleEmptyInput = function(el){  // Si el usuario deja el input de unidades vacio, se setea en 1
        if(!el.value){
            setUnidades(1);
        }
    }

    const handleBtns = function(action){  // Maneja los botones de sumar y restar

        if(action === "REMOVE" && (unidades <= 1 || !unidades)) return;

        if(action === "ADD"){
            setUnidades(Number(unidades) + 1);
        }
        if(action === "REMOVE"){
            setUnidades(Number(unidades) - 1);
        }
    }

    const formatDescription = function(){  // La propiedad "recension" es la descripcion del libro. Es un string que tiene tags html. Esta funcion los limpia.
        const tempElement = document.createElement('div');
        tempElement.innerHTML = isSanteria ? product.descripcion : product.recension;
        const stringWithoutTags = tempElement.textContent || tempElement.innerText || '';
        
        if(stringWithoutTags) {
            return stringWithoutTags  //Reemplazar caracteres que vienen mal desde base de datos
            .replace(/Ã¡/g, "á")
            .replace(/Ã³/g, "ó")
            .replace(/Ã©/g, "é")
            .replace(/Ãº/g, "ú")
            .replace(/Ã±/g, "ñ")
            .replace(/Â¡/g, "¡")
            .replace(/Âª/g, "°")
            .replace(/Â/g, " ")
            .replace(/Ã/g, "í");
        }
        return null;
    }

    const handleCart = function(){  // Agrego productos al carrito

        let productsCart = [];

        if (localStorage.getItem('order')) {            // Si hay algo en el localStorage
            productsCart = localStorage.getItem('order');  // Lo traigo
            productsCart = JSON.parse(productsCart);       // Y lo convierto a JSON

            if (productsCart.filter((e) => e.id_libro === product.id_libro).length > 0 && productsCart.filter((e) => e.id_santeria === product.id_santeria).length > 0 ){  // Si este producto ya existe en el carrito
                productsCart.forEach((p,i)=>{
                    if (p.id_libro === product.id_libro && p.id_santeria === product.id_santeria){
                        p.cant = Number(p.cant) + unidades;          // Le sumo los amounts de esta card
                    }
                })
            } else {                                   //  Si no no existe en el carrito
                productsCart.push({...product, cant: unidades});  //  Lo pusheo
            }

            localStorage.setItem('order', JSON.stringify(productsCart))   // Y subo al localStorage

        } else {                                           // Si no hay nada en el localStorage
            productsCart.push({...product, cant: unidades});    //  Lo pusheo
            localStorage.setItem('order', JSON.stringify(productsCart))   // Y subo al localStorage
        }

        toastSuccess("Producto agregado al carrito");
        dispatch(updateCart());
    }

    const handleReadMore = () => {
        setReadMoreText(!readMoreText);
    }

    return (
        <div id='top' className={s.container}>

            <div className={s.column}>
                <div>
                    <div className={s.columnHeader}>
                        {
                            isSanteria ?
                            <>
                                <p>Objeto</p><p>/</p><p>Detalle</p><p>/</p>
                            </>
                            :
                            <>
                                <p>Libros</p><p>/</p><p>Detalle Libro</p><p>/</p>
                            </>
                        }
                    </div>
                    <nav translate="no">
                        <ul>
                            <li>
                                <p redirect="Informacion" onClick={(e)=>{handleNav(e)}}>Información</p>
                                <div className={s.underline} style={{display: selected !== "Informacion" && "none"}}/>
                            </li>
                            <li>
                                <p redirect="Obras" onClick={(e)=>{handleNav(e)}}>
                                    {isSanteria ? "Más de esta categoría" : "Más obras del autor"}
                                </p>
                                <div className={s.underline} style={{display: selected !== "Obras" && "none"}}/>
                            </li>
                            <li>
                                <p redirect="Categoria" onClick={(e)=>{handleNav(e)}}>
                                    {isSanteria ? "Más de este material" : "Más de la colección"}
                                </p>
                                <div className={s.underline} style={{display: selected !== "Categoria" && "none"}}/>
                            </li>
                            <li>
                                <p redirect="InfoComprar" onClick={(e)=>{handleNav(e)}}>Cómo comprar</p>
                                <div className={s.underline} style={{display: selected !== "InfoComprar" && "none"}}/>
                            </li>
                        </ul>
                    </nav>
                    <div className={s.marcaDeAguaWrapper}>
                        <img alt='Marca de agua' src={logo}/>
                    </div>
                </div>
                <div onClick={()=>window.scrollTo({top: 0, left: 0, behavior: "smooth"})} className={s.VolArrib}>
                        <ul>
                            <li>Volver Arriba <ArrowUpward/></li>
                        </ul>
                </div>
                
            </div>

            <div className={s.content}>
                <section id="Informacion" className={s.informacionContainer}>

                    {
                        isMobile && 
                        <div className={s.titleMobile}>
                            <p>
                                {capitalizeWords(correctEncoding(product.titulo, isSanteria))} <br/>
                                <span className={s.autor}>
                                    {
                                        !isSanteria &&
                                        product.autores?.charAt(0).toUpperCase() + product.autores?.slice(1)
                                    }
                                </span>
                            </p>
                        </div>
                    }

                    {
                        product?.imagen !== "" ?
                        <img alt='Portada' src={`${process.env.REACT_APP_IMG_API}${product.imagen}`}/> :
                        <img alt='Portada' src={nolibro}/>
                    }

                    <div className={s.data}>
                        {
                            !isMobile &&
                            <>
                                <h2>{capitalizeWords(correctEncoding(product.titulo, isSanteria))}</h2>
                                {
                                    !isSanteria && <h3 className={s.autor}>{product.autores?.charAt(0).toUpperCase() + product.autores?.slice(1)}</h3>
                                }
                            </>
                        }
                        
                        {
                            product.precio === product.preciodescuento && (product.preciodescuento != "0.00" && product.precio != "0.00") &&
                            <h3 translate="no" className={s.precio}>${product.precio}</h3>
                        }
                        {
                            product.precio !== product.preciodescuento && (product.preciodescuento != "0.00" && product.precio != "0.00") &&
                            <div>
                                <h3 translate="no" className={s.precioDescuento}>${product.precio}</h3>
                                <h3 translate="no" className={s.precio}>${product.preciodescuento}</h3>
                            </div>
                        }
                        
                        <div className={!readMoreText ? s.descriptionContainer : s.descriptionContainerExtended}>
                            <p className={s.description}>{formatDescription()}</p>
                        </div>

                        {
                            isMobile && !readMoreText && product.recension !== "" &&
                            <div className={s.readMore} 
                                onClick={handleReadMore}>
                                <p>Ver más</p>
                            </div>
                        }
                        
                        {
                            (isMobile && readMoreText) || (isMobile && !readMoreText && product.recension === "") ?
                            <div className={s.dataExtraContainer}>
                                {
                                    isSanteria ?
                                    <>
                                        <p>Categoría: {product.categoria}</p>
                                        {
                                            product.material1 &&
                                            <div>
                                                <p>
                                                    Materiales: 
                                                    {`${product.material1}${product.material2 ? `, ${product.material2}` : ''}${product.material3 ? `, ${product.material3}` : ''}`}
                                                </p>
                                            </div>
                                        }
                                    </>
                                    :
                                    <>
                                        <p>Editorial: {product.editorial.replace(/Ã±|Ñ/g, 'ñ')}</p>
                                        <p>Código ISBN: {product.isbn13}</p>
                                    </>
                                }
                            </div>
                            : null
                        }

                        {
                            !isMobile &&
                            <div className={s.dataExtraContainer}>
                                {
                                    isSanteria ?
                                    <>
                                    <p>Categoría: {product.categoria}</p>
                                    {
                                        product.material1 &&
                                        <div>
                                            <p>
                                                Materiales: 
                                                {`${product.material1}${product.material2 ? `, ${product.material2}` : ''}${product.material3 ? `, ${product.material3}` : ''}`}
                                            </p>
                                        </div>
                                    }
                                    </>
                                    :
                                    <>
                                        <p>Editorial: {product.editorial.replace(/Ã±|Ñ/g, 'ñ')}</p>
                                        <p>Código ISBN: {product.isbn13}</p>
                                    </>
                                }
                            </div>
                        }

                        {
                            isMobile && readMoreText && product.recension !== "" &&
                            <div className={s.readLess} 
                                onClick={handleReadMore}>
                                <p>Ver menos</p>
                            </div>
                        }

                        <div className={s.cantidadContainer}>
                            <p>Cantidad:</p>
                            <div className={s.unidadesContainer}>
                                <Remove sx={{ fontSize: 20, color: "#1C1B1F", cursor: "pointer"}} onClick={()=>{handleBtns("REMOVE");}}/>
                                <input type='number'  min="1" max="99" value={unidades} onBlur={(e)=>{handleEmptyInput(e.target)}} onChange={(e)=>{handleUnidades(e.target);}}/>
                                <Add sx={{ fontSize: 20, color: "#1C1B1F", cursor: "pointer"}} onClick={()=>{handleBtns("ADD");}}/>
                            </div>
                        </div>
                        <div className={s.btnsContainer}>
                            {
                                product.preciodescuento == "0.00" || product.precio == "0.00" ?
                                <button className={s.disabledButton}>
                                    Agregar al carrito
                                </button>
                                :
                                <CallToAction content="Agregar al carrito" onClick={handleCart} detailStyle={true} />
                            }
                            <CallToActionOutline content="Abrir Carrito" onClick={()=>{modalControl(true);}} detailStyle={true} />
                        </div>
                    </div>
                </section>

                <section id="Obras">
                    {
                        isSanteria ?
                        <>
                            <SectionTitle content="Más de esta categoría"/>
                            <SwiperCards products={Array.isArray(product.santeriaCategoria) && product?.santeriaCategoria?.filter(elem => elem.id_santeria !== product.id_santeria)} 
                                detailPage={true}
                            />
                        </>
                        :
                        <>
                            <SectionTitle content="Más obras del autor"/>
                            <SwiperCards products={Array.isArray(product.librosAutor) && product?.librosAutor?.filter(elem => elem.id_libro !== product.id_libro)} 
                                detailPage={true}
                            />
                        </>
                    }
                </section>

                <section id="Categoria" className={s.collectionContainer}>
                    {
                        isSanteria ?
                        <>
                            <SectionTitle content="De este material"/>
                            <SwiperCards products={Array.isArray(product.santeriaMaterial) && product?.santeriaMaterial?.filter(elem => elem.id_santeria !== product.id_santeria)} 
                                detailPage={true}
                            />
                        </>
                        :
                        <>
                            <SectionTitle content="De esta colección"/>
                            <SwiperCards products={Array.isArray(product.librosAutor) && product?.librosColeccion?.filter(elem => elem.id_libro !== product.id_libro)} 
                                detailPage={true}
                            />
                        </>
                    }
                </section>
                
                <section className={s.infoCompras} id="InfoComprar">
                    <InfoCompras/>
                </section>
            </div>
            <Modal
                isOpen={modalCart}
                onRequestClose={()=>{modalControl(false)}}
                style={modalStyles}
                ariaHideApp={false}
                className="modalCartContainer"
            >
                <Cart modalControl={modalControl} type="navbar"/>
            </Modal>
        </div>
    )
}

export default connect(null, { getLibro, getSanteria })(Detalle);