import s from "./FiltrosModalResp.module.css";
import logo from "../../assets/agape logo secondary.png";
import { SelectOutlineFilter, 
    InputOutlineSearch, 
    InputOutlinePrice, 
    CheckboxOutlineFilter, 
    SelectOutlineFilterSanteriaCategory, 
    SelectOutlineFilterSanteriaMaterial, 
} from "../CallToAction/CallToAction";

function FiltrosModalResp({ modalMenuRespControl, 
    categorias, handleCategorias, categoriasSanteria, handleAutor, handleInputFormat, setAutorTextInput, 
    autores, handleEditorial, setEditorialTextInput, editoriales, handlePrice, setPriceTextInput, 
    isChecked, handleCheckbox, handleProductsCheckbox, checkLibros, setCheckLibros, 
    checkSanteria, setCheckSanteria }) {

    const handleSelectChange = (e) => {
        const value = e.target.value;
        const getId = value.match(/\d+/);
        const id = getId[0] || null;
    
        if (value.includes("subId")) {
            // No es una categoría, entonces es una subcategoría.
            handleCategorias("categorias", id, 1);
        } else {
            // Se seleccionó una categoría.
            handleCategorias("categorias", id, 0);
        }
    }

    return (
        <div className={s.container}>
            <div className={s.header}>
                <span onClick={() => { modalMenuRespControl(false) }}>
                    Aplicar
                </span>
            </div>

            <div className={s.navegador}>

                <div className={s.title_container}>
                    <p>Filtros</p>
                    <div className={s.title_underline}></div>
                </div>

                <div style={{display: "flex", gap: "10px"}}>
                        <div>
                            <input type="checkbox" id="checkLibros" checked={checkLibros} onChange={() => handleProductsCheckbox(setCheckLibros, checkLibros)} />
                            <label for="checkLibros">Libros</label>
                        </div>
                        <div>
                            <input type="checkbox" id="checkSanteria" checked={checkSanteria} onChange={() => handleProductsCheckbox(setCheckSanteria, checkSanteria)} />
                            <label for="checkSanteria">Objetos religiosos</label>
                        </div>
                    </div>

                {
                    (categorias?.ofertas?.length > 0 || categoriasSanteria.ofertasSanteria.length > 0) &&
                    <CheckboxOutlineFilter content="Ofertas" isChecked={isChecked} handleCheckbox={handleCheckbox} />
                }
                
                {
                    checkLibros &&
                    <>
                        <SelectOutlineFilter content="Tema" onChange={(e)=>{handleCategorias("temas", e.target.value)}} array={categorias.temas} tema={true} />

                        <InputOutlineSearch content="Autor" onSubmit={handleAutor} onChange={(e)=>{handleInputFormat(e.target.value, setAutorTextInput)}} onClick={handleAutor} array={autores} autor={true} />

                        <InputOutlineSearch content="Editorial" onSubmit={handleEditorial} onChange={(e)=>{handleInputFormat(e.target.value, setEditorialTextInput)}} onClick={handleCategorias} array={editoriales} />

                        <SelectOutlineFilter content="Colección" onChange={(e)=>{handleCategorias("colecciones", e.target.value)}} array={categorias.colecciones} />
                    </>
                }

                {
                    checkSanteria &&
                    <>
                        <SelectOutlineFilterSanteriaCategory content="Categorias" fn={handleSelectChange} array={categoriasSanteria.categorias} />

                        <SelectOutlineFilterSanteriaMaterial content="Materiales" onChange={(e)=>{handleCategorias("materiales", e.target.value)}} array={categoriasSanteria.materiales} />
                    </>
                }

                <InputOutlinePrice onSubmit={handlePrice} onChange={setPriceTextInput} />

                <div className={s.separador} />

                <div className={s.footerFiltros}>
                    <div>
                        <img src={logo} alt="Logo Agape"/>
                        <p>Casa Central <br/> Av. San Martín 6863 - Buenos Aires</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FiltrosModalResp;