import * as constants from "../constants";
import axios from 'axios';

// Las acciones de redux son las funciones que ejecutamos cuando quemos hacer algo relacionado al storage global.
// Las funciones de accion deben terminar con un dispatch({type: constants.X, payload: X}) .
// Esto indica al reducer cuál es la variable del estado global que vamos a escribir y cual es la informacion que guardamos en esa variable.
// constants es objecto que importamos de ./constants/index.js para evitar inconsistencias cuando escribimos el tipo de variable que queremos modificar en el reducer.

export function getToken(callback, parametro){  // Obtiene el token o "api key" que usamos para todas las queries al backend
    return (async(dispatch) => {
        let promise;
        try{
            promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/gettoken.php?client_id=fc7ddd0005d012bfy790fee86ff95401&secret_id=o5ec225b93bed02d005bc70368357611`)
        } catch(e){
            console.log(e)
        }

        if(promise.data.response_code === 200){
            let response = promise.data.response_desc;
            dispatch({type: constants.GET_TOKEN, payload: response})
        } else {
            console.log("Error accediendo al token de seguridad")
        }

        // Recibimos dos parametros opcionales "callback" y "parametro"
        // Si callback es una funcion, quiere decir que otra funcion de este archivo detectó que no hay token, por ende llamo a getToken y se paso A SI MISMA como callback.
        // De esta forma la funcion es llamada de de nuevo una vez que el token existe y puede seguir su funcionamiento normal
        // La variable parametro es para el case en que una accion requiera un parametro. Ejemplo: getLibro(id_libro) => callback(parametro)

        if(typeof callback === "function"){
            dispatch(callback(parametro))
        }

    })
}

export function getLibro(id_libro){  // Nos traemos la informacion de un libro en especifico con su id para mostrar en la pagina de detalle
    return (async(dispatch, getState) => {
        const token = getState().token;

        if(!token){  // Si no hay token, llamamos a getToken, pasamos esta misma funcion con su parametro.
            dispatch(getToken(getLibro, id_libro));
        }

        let response;
        try{
            let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&id_libro=${id_libro}&limite_inferior=0&limite_superior=1`);
            response = promise.data[0];

            let promise1 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=0&limite_superior=30&filtro_autor=${response.autores}`);
            let promise2 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=0&limite_superior=30&filtro_coleccion=${response.coleccion}`);

            // En este caso nos traemos la informacion del libro primero y luego hacemos dos queries mas "promise1" y "promise2" que van a traernos informacion sobre libros relacionados.
            // Agregamos dos propiedades mas al objecto de respuesta original. librosAutor y librosColeccion son dos arrays con mas libros para mostrar.

            await Promise.all([promise1, promise2]).then(values=>{
                response.librosAutor = values[0].data;
                response.librosColeccion = values[1].data;
            })

        } catch(e){
            console.log(e)
        }

        if(!response){  // Si hubo un error, limpiamos el estado.
            dispatch({type: constants.GET_LIBRO, payload: {
                id_libro: null,
                titulo: "",
                nro_coleccion: null,
                autores: "",
                imagen: "",
                precio: 0,
                preciodescuento: 0,
                editorial: "",
                isbn13: "",
                recension: "",
                coleccion: "",
                codigo: "",
                librosAutor: [],
                librosColeccion: []
            }});
        } else {
            dispatch({type: constants.GET_LIBRO, payload: response});
        }


    })
}

export function clearLibro(){  // Limpiamos la infromacion del libro en el estado. Esto se ejecuta al desmontarse el componente detalle.
    return (async(dispatch) => {

        dispatch({type: constants.CLEAR_LIBRO, payload: {
            id_libro: null,
            titulo: "",
            nro_coleccion: null,
            autores: "",
            imagen: "",
            precio: 0,
            preciodescuento: 0,
            editorial: "",
            isbn13: "",
            recension: "",
            coleccion: "",
            codigo: "",
            librosAutor: [],
            librosColeccion: []
        }});

    })
}

export function getCategorias(){  // Nos trae las categorias Temas, Colecciones y Ofertas para mostrar en la pagina "Libros", por ejemplo.
    return (async(dispatch, getState) => {
        const token = getState().token;

        if(!token){
            dispatch(getToken(getCategorias));
        }

        let response;
        try{

            let promise1 = axios.get(`${process.env.REACT_APP_URL_API}servicios/gettemas.php?token=${token}`);
            let promise2 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getcolecciones.php?token=${token}`);
            let promise3 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibrosaccionescom.php?token=${token}`);

            response = {
                temas: [],
                colecciones: [],
                ofertas: []
            };

            await Promise.all([promise1, promise2, promise3]).then(values=>{
                response.temas = values[0].data;
                response.colecciones = values[1].data;
                response.ofertas = Array.isArray(values[2].data) && values[2].data.filter(elem => elem.oferta != 0.00)
            })

        } catch(e){
            console.log(e)
        }


        if(response.temas && response.colecciones && (Array.isArray(response.temas) && Array.isArray(response.colecciones))){
            dispatch({type: constants.GET_CATEGORIAS, payload: response});
        } else {
            dispatch({type: constants.GET_CATEGORIAS, payload: {
                temas: [],
                colecciones: [],
                ofertas: []
            }});
        }
    })
}

export function getLibros(){ // Nos traemos varios libros de categoria "novedades" y "recomendados" para mostrar.
    return (async(dispatch, getState) => {

        const token = getState().token;

        if(!token){
            dispatch(getToken(getLibros));
        }

        let response;
        try{

            let promise1 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibrosdiv.php?token=${token}&id_division=72`);
            let promise2 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getlibrosdiv.php?token=${token}&id_division=11`);

            response = {
                recomendados: [],
                novedades: []
            };

            await Promise.all([promise1, promise2]).then(values=>{
                response.recomendados = values[0].data;
                response.novedades = values[1].data;
            });

        } catch(e){
            console.log(e)
        }

        if(response.recomendados && Array.isArray(response.recomendados) && response.recomendados !== undefined && response.novedades && Array.isArray(response.novedades) && response.novedades !== undefined){
            dispatch({type: constants.GET_LIBROS, payload: response});
        } else {
            dispatch({type: constants.GET_LIBROS, payload: []});
        }

        dispatch({type: constants.GET_LIBROS, payload: response});

    })
}

export function getSanteriaCategorias(){  // Nos trae las categorias Temas y materiales para Santeria.
    return (async(dispatch, getState) => {
        const token = getState().token;

        if(!token){
            dispatch(getToken(getSanteriaCategorias));
        }

        let response;
        try{

            let promise1 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getcategoriassanteria.php?token=${token}`);
            let promise2 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getmateriales.php?token=${token}`);
            let promise3 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getsanteriaaccionescom.php?token=${token}`);

            response = {
                categorias: [],
                materiales: [],
                ofertasSanteria: []
            };

            await Promise.all([promise1, promise2, promise3]).then(values=>{
                response.categorias = values[0].data;
                response.materiales = values[1].data;
                response.ofertasSanteria = Array.isArray(values[2].data) && values[2].data.filter(elem => elem.oferta != 0.00);
            })

        } catch(e){
            console.log(e)
        }


        if(response.categorias && response.materiales && (Array.isArray(response.categorias) && Array.isArray(response.materiales))){
            dispatch({type: constants.GET_SANTERIA_CATEGORIAS, payload: response});
        } else {
            dispatch({type: constants.GET_SANTERIA_CATEGORIAS, payload: {
                categorias: [],
                materiales: [],
                ofertasSanteria: []
            }});
        }
    })
}

export function getSanteria(id_santeria){  // Nos traemos la informacion de una santeria en especifica con su id para mostrar en la pagina de detalle
    return (async(dispatch, getState) => {
        const token = getState().token;

        if(!token){  // Si no hay token, llamamos a getToken, pasamos esta misma funcion con su parametro.
            dispatch(getToken(getSanteria, id_santeria));
        }

        let response;
        try{
            let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&id_santeria=${id_santeria}&limite_inferior=0&limite_superior=1`);
            response = promise.data[0];

            let promise1 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=0&limite_superior=30&filtrotexto=${response.categoria}`);
            let promise2 = axios.get(`${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=0&limite_superior=30&filtrotexto=${response.material1}`);

            // En este caso nos traemos la informacion de la santeria primero y luego hacemos dos queries mas "promise1" y "promise2" que van a traernos informacion sobre santerias relacionadas.
            // Agregamos dos propiedades mas al objecto de respuesta original. santeriaCategoria y santeriaColeccion son dos arrays con mas santeria para mostrar.

            await Promise.all([promise1, promise2]).then(values=>{
                response.santeriaCategoria = values[0].data;
                response.santeriaMaterial = values[1].data;
            })

        } catch(e){
            console.log(e)
        }

        if(!response){  // Si hubo un error, limpiamos el estado.
            dispatch({type: constants.GET_SANTERIA, payload: {
                id_santeria: null,
                titulo: "",
                material1: "",
                material2: "",
                categoria: "",
                isbn13: "",
                lanzamiento: "",
                codigo: "",
                stockMin: "",
                precio: 0,
                imagen: "",
                preciodescuento: 0,
                santeriaCategoria: [],
                santeriaMaterial: []
            }});
        } else {
            dispatch({type: constants.GET_SANTERIA, payload: response});
        }
    })
}

export function clearSanteria(){  // Limpiamos la infromacion de la santeria en el estado. Esto se ejecuta al desmontarse el componente detalle.
    return (async(dispatch) => {

        dispatch({type: constants.CLEAR_SANTERIA, payload: {
            id_santeria: null,
            titulo: "",
            material1: "",
            material2: "",
            categoria: "",
            isbn13: "",
            lanzamiento: "",
            codigo: "",
            stockMin: "",
            precio: 0,
            imagen: "",
            preciodescuento: 0,
            santeriaCategoria: [],
            santeriaMaterial: []
        }});
    })
}

export function updateCart(){  // Ejecutamos esta funcion en cambios en el carrito. Con esta informacion podemos mostrar la burbuja que muestra la cantidad de unidades en el carrito en el navbar.
    return (async(dispatch) => {

        let productsCart = [];
        let productsInStorage = localStorage.getItem('order');

        if(productsInStorage){
            productsCart = JSON.parse(productsInStorage);
        }

        let resultado = 0;
        for(let i = 0; i < productsCart.length; i++){
            resultado += Number(productsCart[i].cant);
        }

        dispatch({type: constants.UPDATE_CART_CANT, payload: resultado});

    })
}

//Guardamos la posicion del navbar al iniciar la app para poder hacer buen uso del position fixed al cambiar de rutas
export function setNavbarPosition(position){  
    return (async(dispatch) => {
        dispatch({type: constants.SET_NAVBAR_POSITION, payload: position});
    })
}