import toast from 'react-hot-toast';
import ReactGa from "react-ga";

export function toastSuccess(text){
    toast.success(text, {
        style: {
          border: '1px solid #badbcc',
          padding: '12px',
          color: '#0f5132',
          backgroundColor: "#D1E7DD"
        },
        duration: 4000,
    });
}

export function toastError(text){
    toast.error(text, {
        style: {
          border: '1px solid #f5c2c7',
          padding: '16px',
          color: '#842029',
          backgroundColor: "#f8d7da"
        },
        duration: 4000,
    });
}

export function capitalizeWords(text) {
  if (!text) return '';

  return text
    .split(" ")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ")
    .replace(/Ã|Ñ/g, 'ñ'); //Corregimos las "Ñ" (problema que viene desde la base de datos)
}

export function correctEncoding(text, isSanteria) {
  if (!text) return;
  if (!isSanteria) return text;

  return text  //Reemplazar caracteres que vienen mal desde base de datos
    .replace(/Ã\x81/g, "á")
    .replace(/Ã±/g, "ñ")
    .replace(/Ã\x91/g, "ñ")
    .replace(/Ã¡/g, "á")
    .replace(/Ã©/g, "é")
    .replace(/Ã/g, "á")
    .replace(/Ã/g, "é")
    .replace(/Ã/g, "ú")
    .replace(/Ã/g, "í")
    .replace(/Ã/g, "ó")
    .replace(/Ã/g, "í")
}

export function formatNumber(number) {
  return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(number)
}

export async function copyToClipboard(text) {
  if ("clipboard" in navigator) {
    try {
      await navigator.clipboard.writeText(text);
      toastSuccess("Se ha copiado el texto al portapapeles");
    } catch (error) {
      toastError("Error al copiar texto al portapapeles");
    }
  } else {
    toastError("Error al copiar texto al portapapeles");
  }
}

export function trackPage() { //Función para detectar visita a la página con Google Analytics
  ReactGa.pageview(window.location.pathname);
}