import { useState, useEffect } from 'react';
import s from "../Libros/Libros.module.css";
import { useSelector, useDispatch } from 'react-redux';
import { getCategorias, getSanteriaCategorias, getToken } from '../../redux/actions';
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Card from '../../components/Card/Card';
import { CallToAction, CallToActionOutlineBooks, SelectOutlineOrder } from '../../components/CallToAction/CallToAction';
import { InfoCompras } from "../../components/InfoCompras/InfoCompras";
import { ArrowForwardIos } from '@mui/icons-material';
import { ArrowUpward } from '@mui/icons-material';
import logo from "../../assets/agape logo rotado.png";
import axios from 'axios';
import { Search, Close } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { capitalizeWords } from '../../helpers';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useMediaQuery from "../../hooks/useMediaQuery";
import Modal from 'react-modal';
import FiltrosModalRespBusqueda from "../../components/FiltrosModalResp/FiltrosModalRespBusqueda";
import "../../components/Navbar/NavbarModalStyles.css";
import { trackPage } from '../../helpers';

function ResultadosBusqueda() {

    const [libros, setLibros] = useState([]); // Libros a renderizar (incluye santerias).
    const [autorTextInput, setAutorTextInput] = useState("");  // Input de busqueda por autor
    const [editorialTextInput, setEditorialTextInput] = useState("");  // Input de busqueda por editorial
    const [priceTextInput, setPriceTextInput] = useState({desde: null, hasta: null});  // Input de busqeuda por precio
    const [editoriales, seteditoriales] = useState([]);  // Editoriales a listar en la columna
    const [autores, setautores] = useState([]);  // Autores a listar en la columna
    const [verMas, setVerMas] = useState({tema: false, editorial: false, coleccion: false});  // Que botón de "Ver mas" de la columna fue clickeado.
    const [loading, setLoading] = useState(true);  // Renderiza "Cargando..." cuando es true.
    const [ordenarHabilitado, setOrdenarHabilitado] = useState(false);
    const [paginaActual, setPaginaActual] = useState(1);
    const [paginaMayor, setPaginaMayor] = useState([]); //Guarda las páginas visitadas para no traer libros de más desde back.
    const [menuFiltros, setMenuFiltros] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const [isChecked, setIsChecked] = useState(false); //Maneja el valor del checkbox de filtro oferta en mobile
    const [checkLibros, setCheckLibros] = useState(true);
    const [checkSanteria, setCheckSanteria] = useState(true);
    const [mobilePages, setMobilePages] = useState(0);

    const [librosLength ,setLibrosLength] = useState();
    const [santeriaLength ,setSanteriaLength] = useState();
    const maxProducts = librosLength + santeriaLength; // Ajusta según tus nombres de tablas
    const totalPages = Math.ceil(maxProducts / 20); // Divide por 20 o cualquier cantidad que estés usando

    const isMobile = useMediaQuery('(max-width: 768px)');
    const topOfPageElement = document.getElementById("top-of-page-mobile");
    
    const modalStyles = {
        overlay: {
            zIndex: 100,
            background: "#00000060",
            height: "100vh",
            width: "120vw",
            marginLeft: "23vw",
            left: "0",
            transform: !overlay ? "translateX(-360px)" : "translateX(0)",
            transition: "all .3s ease-in-out"
        }
    };
    const handleOpenOverlay = () => { //Manejamos el overlay del modal de filtros en mobile para darle estilos
        setOverlay(true);
    }
    const handleCloseOverlay = () => {
        setOverlay(false);
    }

    // Filtros activos en este momento. Cada propiedad es un parametro que podria recibir la query.
    // Cuando esta seteada alguna de las propiedades, van a ser listadas en el string de la query junto con su valor
    // limite_inferior y limite_superior son obligatorias para el backend.
    // Por un error de convencion del nombre del backend, limite_inferior es a partir de que libro vamos a traer. Mientras que limite_superior es la cantidad de libros que vamos a traer.
    const [filtros, setFiltros] = useState({ 
        limite_inferior: 0,
        limite_superior: 200,
        precio_desde: null,
        precio_hasta: null,
        oferta: null,
        filtrosDeLibros: {
            id_tema: [],
            filtro_autor: [],
            filtro_coleccion: [],
            filtro_editoriales: [],
            filtro_titulo: null
        },
        filtrosDeSanterias: {
            id_categoria: [],
            id_material: [],
            filtrotexto: null,
        }
    });

    const [subcategorias, setSubcategorias] = useState([]);
    const [lastClick, setLastClick] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams()  // Nos busca parametros de busqueda del url.

    const dispatch = useDispatch();
    const categorias = useSelector(state => state.categorias);
    const categoriasSanteria = useSelector(state => state.santeriaCategorias);
    const token = useSelector(state => state.token);

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(()=>{
        setLoading(true);

        if(!token){
            dispatch(getToken());
        }

        dispatch(getCategorias());
        dispatch(getSanteriaCategorias());

        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if(topOfPageElement) {
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        if(searchParams.get('search')){ // Si hay un parametro search, buscamos ese libro por titulo.

            handleCategorias("search", searchParams.get('search'));

        } 

    },[dispatch, token, topOfPageElement])

    useEffect(()=>{ // Ejecutamos la misma busqueda por url cada vez que cambia el url o los estados del checkbox

        if(searchParams.get('search')){

            handleCategorias("search", searchParams.get('search'))

        } 

    },[window.location.href, checkLibros, checkSanteria])

    useEffect(()=>{ // Ejecutamos la misma busqueda por url cada vez que cambia el url
        if (editorialTextInput && editorialTextInput.length > 2) {
            handleEditorialAutocomplete();
        } else{
            seteditoriales([]);
        }
    },[editorialTextInput])

    useEffect(()=>{ // Ejecutamos la misma busqueda por url cada vez que cambia el url
        if (autorTextInput && autorTextInput.length > 2) {
            handleAutorAutocomplete();
        } else {
            setautores([]);
        }
    },[autorTextInput])

    async function fetchData(query, query2){
        setLibros([]);
        setMobilePages(0);

        let promise;
        let promise2;
        try{
            if(checkLibros) {
                if(!query2.includes("id_categoria") && !query2.includes("id_material")) {
                    promise = await axios.get(query);
                }
            }
        } catch(e){
            console.log(e);
        }
        try{
            if(checkSanteria) {
                if(!query.includes("id_tema") || !query.includes("filtro_autor") || !query.includes("filtro_coleccion") || !query.includes("filtro_editoriales")) {
                    promise2 = await axios.get(query2);
                }
            }
        } catch(e){
            console.log(e);
        }

        let combinedData = []; //Combinamos libros y santeria en un solo array.
        if (promise?.data && Array.isArray(promise.data)) {
            combinedData = combinedData.concat(promise.data);
            setLibrosLength(promise.data.length); //Guardamos la longitud del array libros y santeria (debajo) para usar en el paginador.
        } else {
            setLibrosLength(0);
        }
        if (promise2?.data && Array.isArray(promise2.data)) {
            combinedData = combinedData.concat(promise2.data);
            setSanteriaLength(promise2.data.length);
        } else {
            setSanteriaLength(0);
        }
        setLibros(combinedData);

        setLoading(false);
        setPaginaActual(1);
        setPaginaMayor([]);
    }

    const handleCategorias = function(type, data, isChildren = 0){  // Llamada al seleccionar un tema, editorial, coleccion o santerias. 

        // Setea una variable query con el url base e itera sobre el objeto estado filtros, concatenando todos los parametros que estan seteados en el estado.
        // Y finalmente actualizando el estado con el nuevo valor que se quiere agregar.
        
        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;
        let query2 = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(type === "temas"){  // Si el tipo de variable a setear es temas...
            for (const [key, value] of Object.entries(filtros)) {   // Iteramos sobre el objeto filtros
                if(key === "limite_inferior"){                      // Asiganmos 0 a query en limite inferior para que siempre
                    query = query + `&${key}=0`;                    // veamos la primer página al cambiar de temas
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
                if(key === "id_tema"){
                    if(data && filtros.filtrosDeLibros.id_tema.length > 0){
                        query = query + `&${key}=${filtros.filtrosDeLibros.id_tema.join(',')},${data}`; //Si ya hay temas en el array, los juntamos para realizar la búsqueda
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, id_tema: [...prev.filtrosDeLibros.id_tema, data]}})); // Guardamos el nuevo estado con el nuevo valor id_tema seteado.
                    } else if(data && filtros.filtrosDeLibros.id_tema.length === 0){
                        query = query + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, id_tema: [data]}}));
                    }
                } else { 
                    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query += `&${key}=${value}`;
                    }
                }
            }
        }

        if(type === "editoriales"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
                if(key === "filtro_editoriales"){
                    if(data && filtros.filtrosDeLibros.filtro_editoriales.length > 0){
                        query = query + `&${key}=${filtros.filtrosDeLibros.filtro_editoriales.join(',')},${data}`; //Si ya hay temas en el array, los juntamos para realizar la búsqueda
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_editoriales: [...prev.filtrosDeLibros.filtro_editoriales, data]}})); // Guardamos el nuevo estado con el nuevo valor id_tema seteado.
                    } else if(data && filtros.filtrosDeLibros.filtro_editoriales.length === 0){
                        query = query + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_editoriales: [data]}}));
                    }
                } else { 
                    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query += `&${key}=${value}`;
                    }
                }
            }

            setEditorialTextInput('');
        }

        if(type === "colecciones"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
                if(key === "filtro_coleccion"){
                    if(data && filtros.filtrosDeLibros.filtro_coleccion.length > 0){
                        query = query + `&${key}=${filtros.filtrosDeLibros.filtro_coleccion.join(',')},${data}`; //Si ya hay temas en el array, los juntamos para realizar la búsqueda
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_coleccion: [...prev.filtrosDeLibros.filtro_coleccion, data]}})); // Guardamos el nuevo estado con el nuevo valor id_tema seteado.
                    } else if(data && filtros.filtrosDeLibros.filtro_coleccion.length === 0){
                        query = query + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_coleccion: [data]}}));
                    }
                } else { 
                    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query += `&${key}=${value}`;
                    }
                }
            }
        }

        if(type === "categorias"){  // Si el tipo de variable a setear es categorias...
            for (const [key, value] of Object.entries(filtros)) {   // Iteramos sobre el objeto filtros
                if(key === "limite_inferior"){                      // Asiganmos 0 a query en limite inferior para que siempre
                    query2 = query2 + `&${key}=0`;                    // veamos la primer página al cambiar de temas
                } else {
                    if((value !== null && typeof value !== "object")){
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
            for (const [key, value] of Object.entries(filtros.filtrosDeSanterias)) {
                if(key === "id_categoria"){
                    let categoriesArray = filtros.filtrosDeSanterias.id_categoria;

                    if(isChildren === 1) {
                        if(lastClick === "categoria") {
                            setFiltros((prev)=>({...prev, filtrosDeSanterias:{...prev.filtrosDeSanterias, id_categoria: []}}));
                            categoriesArray = [];
                        }
                        setLastClick("subcategoria");
                        setSubcategorias(prev => [...prev, data]);
                    } else if(isChildren === 0) {
                        if(lastClick === "subcategoria") {
                            setFiltros((prev)=>({...prev, filtrosDeSanterias:{...prev.filtrosDeSanterias, id_categoria: []}}));
                            setSubcategorias([]);
                            categoriesArray = [];
                        }
                        setLastClick("categoria");
                    }

                    if(data && categoriesArray.length > 0){
                        query2 = query2 + `&${key}=${categoriesArray.join(',')},${data}&eshija=${isChildren}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeSanterias:{...prev.filtrosDeSanterias, id_categoria: [...prev.filtrosDeSanterias.id_categoria, data]}}));
                    } else if(data && categoriesArray.length === 0){
                        query2 = query2 + `&${key}=${data}&eshija=${isChildren}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeSanterias:{...prev.filtrosDeSanterias, id_categoria: [data]}}));
                    }
                } else { 
                    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query2 += `&${key}=${value}`;
                    }
                }
            }
        }

        if(type === "materiales"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query2 = query2 + `&${key}=0`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
            for (const [key, value] of Object.entries(filtros.filtrosDeSanterias)) {
                if(key === "id_material"){
                    if(data && filtros.filtrosDeSanterias.id_material.length > 0){
                        query2 = query2 + `&${key}=${filtros.filtrosDeSanterias.id_material.join(',')},${data}`; //Si ya hay temas en el array, los juntamos para realizar la búsqueda
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeSanterias:{...prev.filtrosDeSanterias, id_material: [...prev.filtrosDeSanterias.id_material, data]}})); // Guardamos el nuevo estado con el nuevo valor id_tema seteado.
                    } else if(data && filtros.filtrosDeSanterias.id_material.length === 0){
                        query2 = query2 + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeSanterias:{...prev.filtrosDeSanterias, id_material: [data]}}));
                    }
                } else { 
                    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query2 += `&${key}=${value}`;
                    }
                }
            }
        }

        if(type === "search"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                    query2 = query2 + `&${key}=0`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }

            // Agregar valores de filtrosDeLibros a query
            for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
                if(key === "filtro_titulo"){
                    query = query + `&${key}=${data}`;
                } else { 
                    if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query += `&${key}=${value}`;
                    }
                }
            }
            // Agregar valores de filtrosDeSanterias a query2
            for (const [key, value] of Object.entries(filtros.filtrosDeSanterias)) {
                if(key === "filtrotexto"){
                    query2 = query2 + `&${key}=${data}`;
                } 
                if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                    query2 += `&${key}=${value}`;
                }   
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_titulo: data}, filtrosDeSanterias:{...prev.filtrosDeSanterias, filtrotexto: data}}));
        }

        if(type === "oferta"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                    query2 = query2 + `&${key}=0`;
                } else if(key === "oferta"){
                    query = query + `&${key}=1`;
                    query2 = query2 + `&${key}=1`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
            for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
                if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                    query += `&${key}=${value}`;
                }
            }
            for (const [key, value] of Object.entries(filtros.filtrosDeSanterias)) {
                if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                    query2 += `&${key}=${value}`;
                }   
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, oferta: "1"}));
        }

        if(lastClick === "subcategoria" && type !== "categorias") {
            query2 = query2 + `&eshija=1`;
        }

        fetchData(query, query2);
    }

    const handleAutor = function(e, autor){

    // Misma iteracion que venimso haciendo pero para el autor. Esta separada de la funcion handleCategorias porque esta funcion se ejectura cuando se hace submit al input de autor.
        if(e) {
            e.preventDefault();
        }
        
        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;

        for (const [key, value] of Object.entries(filtros)) {
            if(key === "limite_inferior"){
                query = query + `&${key}=0`;
            } else {
                if((value !== null && typeof value !== "object")){
                    query = query + `&${key}=${value}`;
                }
            }
        }
        for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
            if(key === "filtro_autor"){
                if(autor && filtros.filtrosDeLibros.filtro_autor.length > 0){
                    query = query + `&${key}=${filtros.filtrosDeLibros.filtro_autor.join(',')},${autor}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_autor: [...prev.filtrosDeLibros.filtro_autor, autor]}}));
                } else if(autor && filtros.filtrosDeLibros.filtro_autor.length === 0){
                    query = query + `&${key}=${autor}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_autor: [autor]}}));
                } else {
                    query = query + `&filtro_titulo=${autorTextInput}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, filtro_autor: [autorTextInput]}}));
                }
            } else {
                if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                    query = query + `&${key}=${value}`;
                }
            }
        }

        setAutorTextInput("");
        fetchData(query);
    }

    const handleAutorAutocomplete = async function(){

        // Al buscar un autor, se hace la query y se listan para luego ser clickeadas y filtrar por ellas.

        if(autorTextInput === "") return;
 
        let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getautores.php?token=${token}&cadena=${autorTextInput}`);

        let response = promise.data;

        if(response){
            setautores(response);
        } else {
            setautores([]);
        }
    }

    const handleEditorial = function(e){
            e.preventDefault();
            
            setLoading(true);
    
            let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;
    
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "filtro_editoriales"){
                    query = query + `&filtro_titulo=${editorialTextInput}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtro_titulo: editorialTextInput}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
    
            setEditorialTextInput("");
            fetchData(query);
        }

    const handleEditorialAutocomplete = async function(){

        // Al buscar una editorial, se hace la query y se listan para luego ser clickeadas y filtrar por ellas.

        if(editorialTextInput === "") return;
 
        let promise = await axios.get(`${process.env.REACT_APP_URL_API}servicios/geteditoriales.php?token=${token}&cadena=${editorialTextInput}`);

        let response = promise.data;

        if(response){
            seteditoriales(response);
        } else {
            seteditoriales([]);
        }

    }

    const handlePrice = function(e){

        e.preventDefault();

        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;
        let query2 = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(lastClick === "subcategoria") {
            query2 = query2 + `&eshija=1`;
        }

        if(priceTextInput.desde && !priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                    query2 = query2 + `&${key}=0`;
                } else if(key === "precio_desde"){
                    query = query + `&${key}=${priceTextInput.desde}`;
                    query2 = query2 + `&${key}=${priceTextInput.desde}`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: priceTextInput.desde, precio_hasta: null}));
        }

        else if(!priceTextInput.desde && priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                    query2 = query2 + `&${key}=0`;
                } else if(key === "precio_hasta"){
                    query = query + `&${key}=${priceTextInput.hasta}`;
                    query2 = query2 + `&${key}=${priceTextInput.hasta}`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: null, precio_hasta: priceTextInput.hasta}));
        }

        else if(priceTextInput.desde && priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                    query2 = query2 + `&${key}=0`;
                }

                if(key === "precio_desde"){
                    query = query + `&precio_desde=${priceTextInput.desde}`;
                    query2 = query2 + `&precio_desde=${priceTextInput.desde}`;
                }
                
                if(key === "precio_hasta"){
                    query = query + `&precio_hasta=${priceTextInput.hasta}`;
                    query2 = query2 + `&precio_hasta=${priceTextInput.hasta}`;
                } 

                if(key !== "precio_desde" && key !== "precio_hasta"){
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: priceTextInput.desde, precio_hasta: priceTextInput.hasta}));
        }

        else if(!priceTextInput.desde && !priceTextInput.hasta){
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: null, precio_hasta: null}));
            return;
        }

        for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
            if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                query += `&${key}=${value}`;
            }
        }
        for (const [key, value] of Object.entries(filtros.filtrosDeSanterias)) {
            if ((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                query2 += `&${key}=${value}`;
            }   
        }

        fetchData(query, query2);
    }

    const fetchNewPage = function(){ 
        if(mobilePages === 0) {
            setMobilePages(40);
        } else {
            setMobilePages(mobilePages + 20);
        }
    }

    const handlePagination = function(page) {
        setPaginaMayor([...paginaMayor, page]);
        setPaginaActual(page);

        window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const formatActiveFilters = function(search){

        // Maneja las burbujitas que aparecen cuando hay un filtro seleccionado.

        let bubbles = []; // Bubbles es un array de objetos con info del filtro, que tiene las burbujas a renderizar.

        // El termino burbujas lo invente yo, no se como se llaman.

        if(filtros.filtrosDeLibros?.id_tema.length > 0){
            filtros.filtrosDeLibros.id_tema.forEach(elem => {

                const themeInCategory = categorias?.temas?.find(tema => tema.id_tema === elem)?.tema;
                const themeName = `Tema: ${themeInCategory}`;
                
                // Verifica si el elemento ya existe en bubbles
                if (!bubbles.some(item => item.value === themeName)) {
                    bubbles.push({ value: themeName, key: "id_tema" });
                }
            });
        }
        if(filtros.precio_desde){
            bubbles.push({value: `Precio Min: $${filtros.precio_desde}`, key: "precio_desde"})
        }
        if(filtros.precio_hasta){
            bubbles.push({value: `Precio Max: $${filtros.precio_hasta}`, key: "precio_hasta"})
        }
        if(filtros.filtrosDeLibros.filtro_autor.length > 0){
            filtros.filtrosDeLibros.filtro_autor.forEach(elem => {
                const authorName = `Autor: ${elem}`;

                if (!bubbles.some(item => item.value === authorName)) {
                    bubbles.push({ value: authorName, key: "filtro_autor" });
                }
            });
        }
        if(filtros.filtrosDeLibros.filtro_coleccion){
            filtros.filtrosDeLibros.filtro_coleccion.forEach(elem => {

                const collectionInCategory = categorias?.colecciones?.find(coleccion => coleccion.coleccion === elem)?.coleccion;
                const collectionName = `Colección: ${collectionInCategory}`;

                // Verifica si el elemento ya existe en bubbles
                if (!bubbles.some(item => item.value === collectionName)) {
                    bubbles.push({ value: collectionName, key: "filtro_coleccion" });
                }
            });
        }
        if(filtros.filtrosDeLibros.filtro_editoriales.length > 0){
            filtros.filtrosDeLibros.filtro_editoriales.forEach(elem => {
                const editorialName = `Editorial: ${elem}`;
                
                if (!bubbles.some(item => item.value === editorialName)) {
                    bubbles.push({ value: editorialName, key: "filtro_editoriales"});
                }
            });
        }
        if(filtros.filtrosDeLibros.filtro_titulo) {
            if(search) {
                bubbles.push({value: `Búsqueda: ${search}`, key: "filtro_titulo"});
            } else {
                bubbles.push({value: `Búsqueda: ${filtros.filtrosDeLibros.filtro_titulo}`, key: "filtro_titulo"});
            }
        }

        if(filtros.filtrosDeSanterias.id_categoria.length > 0){
            if(subcategorias.length > 0) {
                subcategorias.forEach(elem => {
                    const themeInSubCategory = categoriasSanteria?.categorias?.map(subTema => {
                        const hijaEncontrada = subTema.hijas.find(hija => hija.idhija === elem);
                        if (hijaEncontrada) {
                            return hijaEncontrada.categoriahija;
                        }
                    }).filter(nombre => nombre !== undefined).join(', ');
                    const themeName = `Subcategoría: ${themeInSubCategory}`;

                    // Verifica si el elemento ya existe en bubbles
                    if (!bubbles.some(item => item.value === themeName)) {
                        bubbles.push({ value: themeName, key: "id_categoria" });
                    }
                });
            } else {
                filtros.filtrosDeSanterias.id_categoria.forEach(elem => {
                    const themeInCategory = categoriasSanteria?.categorias?.find(tema => tema.id === elem)?.categoria;
                    const themeName = `Categoría: ${themeInCategory}`;

                    if (!bubbles.some(item => item.value === themeName)) {
                        bubbles.push({ value: themeName, key: "id_categoria" });
                    }
                });
            }
        }
        if(filtros.filtrosDeSanterias.id_material){
            filtros.filtrosDeSanterias.id_material.forEach(elem => {

                const collectionInCategory = categoriasSanteria?.materiales?.find(material => material.id === elem)?.material;
                const collectionName = `Material: ${collectionInCategory}`;

                // Verifica si el elemento ya existe en bubbles
                if (!bubbles.some(item => item.value === collectionName)) {
                    bubbles.push({ value: collectionName, key: "id_material" });
                }
            });
        }

        if(filtros.oferta){
            bubbles.push({value: `Oferta: Si`, key: "oferta"})
        }

        if(bubbles.length && !ordenarHabilitado){
            setOrdenarHabilitado(true);
        }

        return <>
        {
            bubbles.length > 1 && !isMobile &&
            <span className={s.bubblesCleanButton} onClick={() => cleanActiveFilters()}>
                Limpiar filtros
            </span>
        }
        {
            bubbles.map((bubble, i)=>{
                return (
                        <div>
                            {
                                !isMobile ? 
                                    <div key={i} className={s.bubble}>
                                        <p>{capitalizeWords(bubble.value)}</p>
                                        <Close sx={{ fontSize: 25, color: "#1c1d3e", cursor: "pointer"}} 
                                            onClick={()=>{handleBubbleClose(bubble.key, bubbles.length, bubble.value);}}/>
                                    </div>
                                    :
                                    <div key={i} className={s.bubble} onClick={()=>{handleBubbleClose(bubble.key, bubbles.length, bubble.value);}}>
                                        <p>
                                            {
                                                bubble.key === "oferta" ?
                                                "Oferta"
                                                :
                                                capitalizeWords(bubble.value.substring(bubble.value.indexOf(":") + 2))
                                            }
                                        </p>
                                    </div>
                            }
                        </div>
                )
            })
        }
        </>

    }

    const handleBubbleClose = function(targetKey, bubblesLength, targetValue) {

        // Al cerrar una burbuja, generamos el nuevo string query sin ese parametro y seteamos el estado de filtros sacando ese parametro.
        let cleanValue;
        setLoading(true);

        //Modificamos el string para quitarle el "Autor:". En el caso de tema tambien obtenemos el id y en coleccion el nombre para poder eliminarlos.
        if (targetKey === "id_tema") {
            cleanValue = categorias?.temas?.find(tema => tema.tema === targetValue.substring(targetValue.indexOf(":") + 2))?.id_tema;
        } else if (targetKey === "filtro_coleccion") {
            cleanValue = categorias?.colecciones?.find(elem => elem.coleccion === targetValue.substring(targetValue.indexOf(":") + 2))?.coleccion;
        } else if (targetValue.includes("Categoría")) {
            cleanValue = categoriasSanteria?.categorias?.find(elem => elem.categoria === targetValue.substring(targetValue.indexOf(":") + 2))?.id;
        } else if (targetKey === "id_material") {
            cleanValue = categoriasSanteria?.materiales?.find(elem => elem.material === targetValue.substring(targetValue.indexOf(":") + 2))?.id;
        } else if (targetValue.includes("Subcategoría")) {
            cleanValue = categoriasSanteria?.categorias?.map(subTema => {
                const hijaEncontrada = subTema.hijas.find(hija => hija.categoriahija ===  targetValue.substring(targetValue.indexOf(":") + 2));
                if (hijaEncontrada) {
                    return hijaEncontrada.idhija;
                }
            }).filter(nombre => nombre !== undefined).join(', ');

            const newSubcategories = subcategorias.filter(elem => elem !== cleanValue);
            setSubcategorias(newSubcategories);
        } else {
            cleanValue = targetValue.substring(targetValue.indexOf(":") + 2);
        }

        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}`;
        let query2 = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(lastClick === "subcategoria") {
            query2 = query2 + `&eshija=1`;
        } 

        for (const [key, value] of Object.entries(filtros)) {
            if(value !== null){
                if(key === targetKey && !Array.isArray(value)){
                    setFiltros((prev)=>({...prev, [targetKey]: null}));
                } else if (key === "limite_inferior"){
                    query = query + `&${key}=0`;
                    query2 = query2 + `&${key}=0`;
                } else {
                    if((value !== null && typeof value !== "object")){
                        query = query + `&${key}=${value}`;
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
        }
        for (const [key, value] of Object.entries(filtros.filtrosDeLibros)) {
            if(value !== null){
                if(key === targetKey && (Array.isArray(value) && value.length > 0)){  //Si el valor es un array ingresamos para eliminar el elemento que se quiere eliminar
                    let deleteValue = filtros.filtrosDeLibros[targetKey].filter(elem => elem !== cleanValue);
                    query = query + `&${key}=${deleteValue.join(',')}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeLibros:{...prev.filtrosDeLibros, [targetKey]: deleteValue}}));
                } else if(key === targetKey && !Array.isArray(value)){
                    setFiltros((prev)=>({...prev, filtrosDeLibros:{...prev.filtrosDeLibros, [targetKey]: null}}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }
        for (const [key, value] of Object.entries(filtros.filtrosDeSanterias)) {
            if(value !== null){
                if(key === "filtrotexto" && targetKey === "filtro_titulo"){ //Busqueda se guarda como filtro_titulo por lo tanto si quiere borrarse lo buscamos con ese nombre.
                    setFiltros((prev)=>({...prev, filtrosDeSanterias:{...prev.filtrosDeSanterias, filtrotexto: null}}));
                } else if(key === targetKey && (Array.isArray(value) && value.length > 0)){  //Si el valor es un array ingresamos para eliminar el elemento que se quiere eliminar
                    let deleteValue = filtros.filtrosDeSanterias[targetKey].filter(elem => elem !== cleanValue);
                    query2 = query2 + `&${key}=${deleteValue.join(',')}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, filtrosDeSanterias:{...prev.filtrosDeSanterias, [targetKey]: deleteValue}}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query2 = query2 + `&${key}=${value}`;
                    }
                }
            }
        }

        // Si se cerraron todas las burbujas, no hay nada que filtrar y nos traemos todos los libros y santerias.

        if(bubblesLength === 1){
            query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}&filtro_editoriales=agape%20libros`;
            query2 = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}`;

            // Si no hay filtros, deshabilitamos el select de orden, porque el servicio de recomendados no tiene filtro por orden. (Ahora traemos todos los libros, no los recomendados).
            setOrdenarHabilitado(false)
        }

        if (targetKey === "oferta") { //Cambiamos el estado del checkbox de oferta en mobile para desmarcarlo
            setIsChecked(!isChecked);
        }
        
        fetchData(query, query2);
    }

    const cleanActiveFilters = function() {
        let query = `${process.env.REACT_APP_URL_API}servicios/getlibros.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}&filtro_editoriales=agape%20libros`;
        let query2 = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}`;

        for (const [key, value] of Object.entries(filtros)) {
            if (typeof value === "string") {
                setFiltros((prev)=>({...prev, [key]: null}));
            }
            if ((Array.isArray(value) && value.length > 0)) {
                setFiltros((prev)=>({...prev, [key]: []}));
            }
        }

        setOrdenarHabilitado(false);
        fetchData(query, query2);
    }

    const handleInputFormat = function(search, fn) {
        // Elimina caracteres especiales y números del valor del input
        const cleanedValue = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z\s]/g, '');
        fn(cleanedValue);
    }

    const modalFiltrosControl = function (bool) {
        setMenuFiltros(bool);
    }

    const handleCheckbox = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            handleCategorias("oferta");
        } else {
            handleBubbleClose("oferta", "", "Oferta: Si");
        }
    }

    const handleProductsCheckbox = (fn, value) => {
        const newValue = !value;
        fn(newValue);
    }

    const handleOrder = (order) => {
        if(order === "precio_DESC"){
            const librosOrdenados = [...libros].sort((a, b) => b.preciodescuento - a.preciodescuento);
            setLibros(librosOrdenados);
        }
        if(order === "precio_ASC"){
            const librosOrdenados = [...libros].sort((a, b) => a.preciodescuento - b.preciodescuento);
            setLibros(librosOrdenados);
        }
        if(order === "titulo_ASC"){
            const librosOrdenados = [...libros].sort((a, b) => a.titulo.localeCompare(b.titulo));
            setLibros(librosOrdenados);
        }
        if(order === "titulo_DESC"){
            const librosOrdenados = [...libros].sort((a, b) => b.titulo.localeCompare(a.titulo));
            setLibros(librosOrdenados);
        }
        if(order === "antiguedad_DESC"){
            const librosOrdenados = [...libros].sort((a, b) => {
                a = a.lanzamiento.split('-').join('');
                b = b.lanzamiento.split('-').join('');
                return b.localeCompare(a);
            });
            setLibros(librosOrdenados);
        }
        if(order === "antiguedad_ASC"){
            const librosOrdenados = [...libros].sort((a, b) => {
                a = a.lanzamiento.split('-').join('');
                b = b.lanzamiento.split('-').join('');
                return a.localeCompare(b);
            });
            setLibros(librosOrdenados);
        }
    }

    return (
        <>
            {/*  Modal filtros en mobile */}
            {
                isMobile &&
                <>
                    <div className={s.mobileFilterButtons}>
                        <CallToActionOutlineBooks content="Filtros" onClick={() => modalFiltrosControl(true)} />
                        <SelectOutlineOrder content="Ordenar por" onChange={(e)=>{ handleOrder(e.target.value) }} />
                    </div>

                    {/* Burbujas */}
                    <div className={s.bubblesContainer}>
                        {
                            formatActiveFilters(searchParams.get('search'))
                        }
                    </div>

                    <Modal
                        isOpen={menuFiltros}
                        onRequestClose={() => { modalFiltrosControl(false) }}
                        style={modalStyles}
                        ariaHideApp={false}
                        className="modalFilterContainer"
                        onAfterOpen={handleOpenOverlay}
                        onAfterClose={handleCloseOverlay}
                    >
                        <FiltrosModalRespBusqueda modalMenuRespControl={modalFiltrosControl} categorias={categorias} handleCategorias={handleCategorias} 
                            categoriasSanteria={categoriasSanteria} handleAutor={handleAutor} handleInputFormat={handleInputFormat} setAutorTextInput={setAutorTextInput} 
                            autores={autores} handleEditorial={handleEditorial} setEditorialTextInput={setEditorialTextInput} editoriales={editoriales} 
                            handlePrice={handlePrice} setPriceTextInput={setPriceTextInput} isChecked={isChecked} handleCheckbox={handleCheckbox} 
                            handleProductsCheckbox={handleProductsCheckbox} checkLibros={checkLibros} setCheckLibros={setCheckLibros} 
                            checkSanteria={checkSanteria} setCheckSanteria={setCheckSanteria} />
                    </Modal>
                </>
            }
        
        <div className={s.container}>

            {
                !isMobile &&
                <div className={s.column}>
                    <div className={s.columnHeader}>
                        <p>Resultados</p><p>/</p>
                    </div>

                    <div className={s.columnHeader}>
                        <div>
                            <input style={{cursor: "pointer"}} type="checkbox" id="checkLibros" checked={checkLibros} onChange={() => handleProductsCheckbox(setCheckLibros, checkLibros)} />
                            <label for="checkLibros" style={{cursor: "pointer"}}>Libros</label>
                        </div>
                        <div>
                            <input style={{cursor: "pointer"}} type="checkbox" id="checkSanteria" checked={checkSanteria} onChange={() => handleProductsCheckbox(setCheckSanteria, checkSanteria)} />
                            <label for="checkSanteria" style={{cursor: "pointer"}}>Objetos religiosos</label>
                        </div>
                    </div>

                    {/* Burbujas */}
                    <div className={s.bubblesContainer}>
                        {
                            formatActiveFilters(searchParams.get('search'))
                        }
                    </div>

                    <div className={s.columnContent}>

                        {/* Select de orden */}
                        { 
                            ordenarHabilitado == true && 
                            <div className={s.selectContainer}>
                                <p>Ordenar por</p>
                                <select onChange={(e)=>{ handleOrder(e.target.value) }} id="select">
                                    <option value="default">Seleccione</option>
                                    <option value="precio_DESC">Mayor precio</option>
                                    <option value="precio_ASC">Menor precio</option>
                                    <option value="titulo_ASC">A - Z</option>
                                    <option value="titulo_DESC">Z - A</option>
                                    <option value="antiguedad_DESC">Mas reciente</option>
                                    <option value="antiguedad_ASC">Mas antiguo</option>
                                </select>
                            </div>
                        }

                        {/* Filtro de ofertas si existen */}
                        {
                            (categorias?.ofertas?.length > 0 || categoriasSanteria?.ofertasSanteria?.length > 0) &&
                            <div className={s.ofertas} onClick={() => handleCategorias("oferta")}>
                                <h3>Ofertas</h3>
                            </div>
                        }

                        {
                            checkLibros &&
                            <>
                                {/* Lista de temas */}
                                <div className={s.category}>
                                    <h3>Tema</h3>
                                    <ul>
                                        {
                                            categorias?.temas?.length === 0 &&
                                            <LoadingSpinner/>
                                        }
                                        {
                                            categorias?.temas?.length > 5 ?
                                            categorias?.temas?.map((tema, index)=>{
                                                if(index < 5 || verMas.tema){
                                                    return <li key={index} onClick={(e)=>{handleCategorias("temas", tema.id_tema)}}>{tema.tema}</li>
                                                }
                                            }) :
                                            categorias?.temas?.map((tema, index)=>{
                                                return <li key={index} onClick={(e)=>{handleCategorias("temas", tema.id_tema)}}>{tema.tema}</li>
                                            })
                                        }
                                        {
                                            (categorias?.temas?.length > 5 && !verMas.tema)&&
                                            <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, tema: true})) }}>Mostrar más</li>
                                        }
                                    </ul>
                                </div>

                                {/* Input de busqueda de autor */}
                                <div className={s.category}>
                                    <h3>Autor</h3>
                                    <form onSubmit={handleAutor}>
                                        <div className={s.inputWrapper}>
                                            <Search sx={{ fontSize: 25, color: "rgba(60, 60, 67, 0.6)"}}/>
                                            <input type='text' placeholder='BUSCAR' onChange={(e)=>{handleInputFormat(e.target.value, setAutorTextInput)}} required pattern=".{3,}" title="Ingresar al menos 3 caracteres" />
                                            <ul>
                                                {
                                                    autores?.length > 5 ?
                                                    autores?.map((autor, index)=>{
                                                        if(index < 5){
                                                            return <li key={index} onClick={(e)=>{handleAutor(e, autor.autor)}}>{capitalizeWords(autor.autor)}</li>
                                                        }
                                                    }) :
                                                    autores?.map((autor, index)=>{
                                                        return <li key={index} onClick={(e)=>{handleAutor(e, autor.autor)}}>{capitalizeWords(autor.autor)}</li>
                                                    })
                                                }
                                            </ul>
                                        </div>
                                    </form>
                                </div>

                                {/* Input de busqeuda de editoriales y lista de editoriales */}
                                <div className={s.category}>
                                    <h3>Editorial</h3>
                                    <form onSubmit={handleEditorial}>
                                        <div className={s.inputWrapper}>
                                            <Search sx={{ fontSize: 25, color: "rgba(60, 60, 67, 0.6)"}}/>
                                            <input type='text' placeholder='BUSCAR' onChange={(e)=>{handleInputFormat(e.target.value, setEditorialTextInput)}} required pattern=".{3,}" title="Ingresar al menos 3 caracteres" />
                                            <ul>
                                                {
                                                    editoriales?.length > 5 ?
                                                    editoriales?.map((editorial, index)=>{
                                                        if(index < 5 || verMas.editorial){
                                                            return <li key={index} onClick={(e)=>{handleCategorias("editoriales", editorial.editorial)}}>{editorial.editorial}</li>
                                                        }
                                                    }) :
                                                    editoriales?.map((editorial, index)=>{
                                                        return <li key={index} onClick={(e)=>{handleCategorias("editoriales", editorial.editorial)}}>{editorial.editorial}</li>
                                                    })
                                                }
                                                {
                                                    (editoriales.length > 5 && !verMas.editorial)&&
                                                    <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, editorial: true})) }}>Mostrar más</li>
                                                }
                                            </ul>
                                        </div>
                                    </form>
                                </div>

                                {/* Lista de colecciones */}
                                <div className={s.category}>
                                    <h3>Colección</h3>
                                    <ul>
                                        {
                                            categorias?.colecciones?.length === 0 &&
                                            <LoadingSpinner/>
                                        }
                                        {
                                            categorias?.colecciones?.length > 5 ?
                                            categorias?.colecciones?.map((coleccion, index)=>{
                                                if(index < 5 || verMas.coleccion){
                                                    return <li key={index} onClick={(e)=>{handleCategorias("colecciones", coleccion.coleccion)}}>{coleccion.coleccion}</li>
                                                }
                                            }) :
                                            categorias?.colecciones?.map((coleccion, index)=>{
                                                return <li key={index} onClick={(e)=>{handleCategorias("colecciones", coleccion.coleccion)}}>{coleccion.coleccion}</li>
                                            })
                                        }
                                        {
                                            (categorias?.colecciones?.length > 5 && !verMas.coleccion)&&
                                            <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, coleccion: true})) }}>Mostrar más</li>
                                        }
                                    </ul>
                                </div>
                            </>
                        }

                        {
                            checkSanteria &&
                            <>
                                {/* Lista de categorias */}
                                <div className={s.category}>
                                    <h3>Categorías</h3>
                                    <ul>
                                        {
                                            categoriasSanteria?.categorias?.length === 0 &&
                                            <LoadingSpinner/>
                                        }
                                        {
                                            categoriasSanteria?.categorias?.length > 5 ?
                                            categoriasSanteria?.categorias?.map((elem, index)=>{
                                                if(index < 5 || verMas.categoria){
                                                    return (
                                                        <li key={index} onClick={(e)=>{handleCategorias("categorias", elem.id)}}>
                                                            <span style={{fontWeight: "600"}}>{elem.categoria}</span>
                                                            {
                                                                elem.hijas.length > 0 &&
                                                                <ul>
                                                                    {elem.hijas.map((elem) => (
                                                                        <li key={elem.idhija}
                                                                        onClick={(e)=>{
                                                                            e.stopPropagation();
                                                                            handleCategorias("categorias", elem.idhija, 1)
                                                                            }}
                                                                        >
                                                                            {elem.categoriahija}
                                                                        </li>
                                                                    ))}
                                                                </ul>
                                                            }
                                                        </li>
                                                    )
                                                }
                                            }) :
                                            categoriasSanteria?.categorias?.map((elem, index)=>{
                                                return <li key={index} onClick={(e)=>{handleCategorias("categorias", elem.id)}}>
                                                    <span style={{fontWeight: "600"}}>{elem.categoria}</span>
                                                    {
                                                        elem.hijas.length > 0 &&
                                                        <ul>
                                                            {elem.hijas.map((elem) => (
                                                                <li key={elem.idhija}
                                                                onClick={(e)=>{
                                                                    e.stopPropagation();
                                                                    handleCategorias("categorias", elem.idhija, 1)
                                                                }}
                                                                >
                                                                    {elem.categoriahija}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                </li>
                                            })
                                        }
                                        {
                                            (categoriasSanteria?.categorias?.length > 5 && !verMas.categoria)&&
                                            <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, categoria: true})) }}>Mostrar más</li>
                                        }
                                    </ul>
                                </div>

                                {/* Lista de materiales */}
                                <div className={s.category}>
                                    <h3>Materiales</h3>
                                    <ul>
                                        {
                                            categoriasSanteria?.materiales?.length === 0 &&
                                            <LoadingSpinner/>
                                        }
                                        {
                                            categoriasSanteria?.materiales?.length > 5 ?
                                            categoriasSanteria?.materiales?.map((elem, index)=>{
                                                if(index < 5 || verMas.material){
                                                    return <li key={index} onClick={(e)=>{handleCategorias("materiales", elem.id)}}>{elem.material}</li>
                                                }
                                            }) :
                                            categoriasSanteria?.materiales?.map((elem, index)=>{
                                                return <li key={index} onClick={(e)=>{handleCategorias("materiales", elem.id)}}>{elem.material}</li>
                                            })
                                        }
                                        {
                                            (categoriasSanteria?.materiales?.length > 5 && !verMas.material)&&
                                            <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, material: true})) }}>Mostrar más</li>
                                        }
                                    </ul>
                                </div>
                            </>
                        }

                        {/* Inputs de busqueda de precios */}
                        <div className={s.category}>
                            <h3>Precio</h3>
                            <form onSubmit={handlePrice}>
                                <div className={s.precioContainer}>
                                    <input type='number' placeholder='Mínimo' min="0" onChange={(e)=>{setPriceTextInput((prev)=>({...prev, desde: e.target.value}))}}/>
                                    <input type='number' placeholder='Máximo' min="0" onChange={(e)=>{setPriceTextInput((prev)=>({...prev, hasta: e.target.value}))}}/>
                                    <button type='submit'>
                                        <ArrowForwardIos sx={{ fontSize: 20, color: "#1C1B1F" }}/>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div onClick={()=>window.scrollTo({top: 0, left: 0, behavior: "smooth"})} className={s.VolArrib}>
                        <ul>
                            <li>Volver Arriba <ArrowUpward/> </li>
                        </ul>
                    </div>

                    {/* Marca de agua del fondo de la columna */}
                    <div className={s.marcaDeAguaWrapper}>
                        <img alt='Marca de agua' src={logo}/>
                    </div>
                </div>
            }

            {/* Renderizamos tarjetas para los libros */}
            <div className={s.content}>
                <SectionTitle content="Resultados Búsqueda" styles={true} />
                <div className={s.cards}>
                    {
                        isMobile ? 
                        libros.slice(0, mobilePages === 0 ? 20 : mobilePages).map((item, index) => {
                            return <Card product={item} isSanteria={item.id_santeria ? true : false} key={index} />;
                        })                     
                        :
                        libros?.slice((paginaActual - 1) * 20, paginaActual * 20).map((libro,index)=>{
                            return <Card product={libro} isSanteria={libro.id_santeria ? true : false} key={index}/>
                        })
                    }
                    {
                        loading ?
                        <div className={isMobile ? s.loadingContainer : ""}>
                            <p className={s.loading}>Buscando...</p>
                        </div>
                        
                        :
                        !libros?.length &&
                        <div className={isMobile ? s.loadingContainer : ""}>
                            <p className={s.loading}>No hay resultados</p>
                        </div>
                    }
                </div>

                {/* Boton de ver mas si es que hay mas para ver... (mobile)*/}
                {
                    isMobile && libros?.length !== 0 && mobilePages <= libros.length - 20 &&
                    <CallToAction content="Ver más" onClick={()=>{fetchNewPage();}}/>
                }
                
                {/* Paginador (tablet, desktop)*/}
                {
                    !isMobile && libros?.length !== 0 &&
                    <Stack spacing={2}>
                        <Pagination 
                            count={ checkLibros && checkSanteria ? totalPages : 
                                checkLibros && !checkSanteria ? Math.ceil(librosLength / 20) : 
                                Math.ceil(santeriaLength / 20)
                            }
                            shape="rounded" 
                            page={paginaActual}
                            onChange={(e, page) => handlePagination(page)}
                            color="primary" 
                            sx={{
                                ".Mui-selected": {
                                    color: "white!important",
                                    backgroundColor: "#4d92cf!important",
                                },
                                ".Mui-selected:hover": {
                                    backgroundColor: "#4d92cf!important",
                                }
                            }}
                        />
                    </Stack>
                }

                <InfoCompras/>
            </div>
        </div>
    </>
    )
}

export default ResultadosBusqueda;