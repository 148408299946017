import { useState } from 'react';
import s from "./MenResp.module.css";
import { Close } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import FootResp from '../FootResp/FootResp.js';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';

function MenResp({ modalMenuRespControl }) {

    const [selected, setSelected] = useState(window.location.href.split("/")[3] || "Inicio");
    const Navigate = useNavigate();
    const handleBtns = function (e) {
        setSelected(e.target.id);

        if (e.target.id === "Inicio") {
            Navigate('/');
        } else {
            Navigate(`/${e.target.id}`);
        }
    }

    return (
        <div className={s.container}>
            <div className={s.header}>
                <Close sx={{ fontSize: 28, color: "#FFFFFF", cursor: "pointer" }} onClick={() => { modalMenuRespControl(false) }} />
                <div>
                    <MenuOutlinedIcon sx={{ fontSize: 20, color: "#FFFFFF" }} />
                    <p>Menú</p>
                </div>
                <div />
            </div>
            <div className={s.navegador}>
                <div className={s.layoutDivResp} />

                <nav>
                    <ul>
                        <li>
                            <p id="Inicio" style={{ scale: selected === "Inicio" && "1.2" }} onClick={(e) => { handleBtns(e); modalMenuRespControl(false) }}>INICIO</p>
                            <div className={s.underline} style={{ display: selected !== "Inicio" && "none" }} />
                        </li>
                        <li>
                            <p id="Libros" style={{ scale: selected === "Libros" && "1.2" }} onClick={(e) => { handleBtns(e); modalMenuRespControl(false) }}>LIBROS</p>
                            <div className={s.underline} style={{ display: selected !== "Libros" && "none" }} />
                        </li>
                        <li>
                            <p id="Objetos" style={{ scale: selected === "Objetos" && "1.1" }} onClick={(e) => { handleBtns(e); modalMenuRespControl(false) }}>OBJETOS RELIGIOSOS</p>
                            <div className={s.underline} style={{ display: selected !== "Objetos" && "none" }} />
                        </li>
                        <li>
                            <p id="Sucursales" style={{ scale: selected === "Sucursales" && "1.2" }} onClick={(e) => { handleBtns(e); modalMenuRespControl(false) }}>SUCURSALES</p>
                            <div className={s.underline} style={{ display: selected !== "Sucursales" && "none" }} />
                        </li>
                        <li>
                            <p id="Contacto" style={{ scale: selected === "Contacto" && "1.2" }} onClick={(e) => { handleBtns(e); modalMenuRespControl(false) }}>CONTACTO</p>
                            <div className={s.underline} style={{ display: selected !== "Contacto" && "none" }} />
                        </li>
                    </ul>
                </nav>
                <div className={s.separador} />
                <div>
                    <FootResp />
                </div>
            </div>
        </div>
    )
}

export default MenResp;