import React, { useState, useEffect } from 'react';
import s from "./Santeria.module.css";
import { useSelector, useDispatch } from 'react-redux';
import { getSanteriaCategorias, getToken } from '../../redux/actions';
import SectionTitle from "../../components/SectionTitle/SectionTitle";
import Card from '../../components/Card/Card';
import { CallToAction, CallToActionOutlineBooks, SelectOutlineOrder } from '../../components/CallToAction/CallToAction';
import { InfoCompras } from "../../components/InfoCompras/InfoCompras";
import { ArrowForwardIos } from '@mui/icons-material';
import { ArrowUpward } from '@mui/icons-material';
import logo from "../../assets/agape logo rotado.png";
import axios from 'axios';
import { Close } from '@mui/icons-material';
import { useSearchParams } from 'react-router-dom';
import LoadingSpinner from "../../components/LoadingSpinner/LoadingSpinner";
import { capitalizeWords } from '../../helpers';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import useMediaQuery from "../../hooks/useMediaQuery";
import Modal from 'react-modal';
import FiltrosModalRespSanteria from "../../components/FiltrosModalResp/FiltrosModalRespSanteria";
import "../../components/Navbar/NavbarModalStyles.css";
import { trackPage } from '../../helpers';

function Santeria() {

    const [santerias, setSanterias] = useState([]); // Santerias a renderizar
    const [priceTextInput, setPriceTextInput] = useState({desde: null, hasta: null});  // Input de busqeuda por precio
    const [verMas, setVerMas] = useState({categoria: false, material: false});  // Que botón de "Ver mas" de la columna fue clickeado.
    const [loading, setLoading] = useState(true);  // Renderiza "Cargando..." cuando es true.
    const [ordenarHabilitado, setOrdenarHabilitado] = useState(false);
    const [paginaActual, setPaginaActual] = useState(1);
    const [paginaMayor, setPaginaMayor] = useState([]); //Guarda las páginas visitadas para no traer santerias de más desde back.
    const [menuFiltros, setMenuFiltros] = useState(false);
    const [overlay, setOverlay] = useState(false);
    const [isChecked, setIsChecked] = useState(false); //Maneja el valor del checkbox de filtro oferta en mobile

    const isMobile = useMediaQuery('(max-width: 768px)');
    const topOfPageElement = document.getElementById("top-of-page-mobile");

    const modalStyles = {
        overlay: {
            zIndex: 100,
            background: "#00000060",
            height: "100vh",
            width: "120vw",
            marginLeft: "23vw",
            left: "0",
            transform: !overlay ? "translateX(-360px)" : "translateX(0)",
            transition: "all .3s ease-in-out"
        }
    };
    const handleOpenOverlay = () => { //Manejamos el overlay del modal de filtros en mobile para darle estilos
        setOverlay(true);
    }
    const handleCloseOverlay = () => {
        setOverlay(false);
    }

    // Filtros activos en este momento. Cada propiedad es un parametro que podria recibir la query.
    // Cuando esta seteada alguna de las propiedades, van a ser listadas en el string de la query junto con su valor
    // limite_inferior y limite_superior son obligatorias para el backend.
    // Por un error de convencion del nombre del backend, limite_inferior es a partir de que santeria vamos a traer. Mientras que limite_superior es la cantidad de santerias que vamos a traer.
    const [filtros, setFiltros] = useState({ 
        limite_inferior: 0,
        limite_superior: 20,
        id_categoria: [],
        precio_desde: null,
        precio_hasta: null,
        id_material: [],
        filtrotexto: null,
        orden: null,
        campo: null,
        oferta: null
    });
    const [subcategorias, setSubcategorias] = useState([]);
    const [lastClick, setLastClick] = useState(null);

    const [searchParams, setSearchParams] = useSearchParams()  // Nos busca parametros de busqueda del url.

    const dispatch = useDispatch();
    const categorias = useSelector(state => state.santeriaCategorias);
    const token = useSelector(state => state.token);

    useEffect(() => {
        trackPage();
    },[]);

    useEffect(()=>{
        setLoading(true);

        if(!token){
            dispatch(getToken());
        }

        dispatch(getSanteriaCategorias());

        window.scrollTo({top: 0, left: 0, behavior: "smooth"});
        if(topOfPageElement) {
            topOfPageElement.scrollIntoView({ behavior: 'smooth' });
        }

        if(searchParams.get('search')){ // Si hay un parametro search, buscamos esa santeria por titulo.

            handleCategorias("search", searchParams.get('search'));

        } else if(searchParams.get('categoria')){

            handleCategorias("categorias", searchParams.get('categoria')); // Si hay un parametro search, buscamos ese id_tema

        } else if(searchParams.get('materiales')){

            handleCategorias("materiales", searchParams.get('materiales'));

        } else {  // Si no, traemos todas las santerias.

            let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}`;
            fetchData(query);

        }

    },[dispatch, token, topOfPageElement])

    useEffect(()=>{ // Ejecutamos la misma busqueda por url cada vez que cambia el url

        if(searchParams.get('search')){

            handleCategorias("search", searchParams.get('search'))

        } else if(searchParams.get('categoria')){

            handleCategorias("categorias", searchParams.get('categoria'));

        } else if(searchParams.get('materiales')){

            handleCategorias("materiales", searchParams.get('materiales'));

        } else {
            let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}`;
            fetchData(query);
        }

    },[window.location.href])

    async function fetchData(query){

        // fetchData hace la query que recibe por parametro como string. Cada modificaion a los filtros concatena nuevos parametros en el string de la query.
        // Por ejemplo: Si filtros tiene seteado id_categoria: 8 y id_material: 4, el string que reciba query sera:
        // https://agape-libros.com.ar/2023/servicios/getsanterias.php?token=${token}&limite_inferior=0&limite_superior=30&id_categoria=22&id_material=papa

        setSanterias([]);

        let promise;
        try{
            promise = await axios.get(query);
        } catch(e){
            console.log(e);
        }

        if(!promise.data || !Array.isArray(promise.data)){
            setSanterias([]);
        } else {
            setSanterias(promise.data);
        }

        setLoading(false);
        setPaginaActual(1);
        setPaginaMayor([]);
    }

    const handleCategorias = function(type, data, isChildren = 0){  // Llamada al seleccionar una categoria o material. 
        // Setea una variable query con el url base e itera sobre el objeto estado filtros, concatenando todos los parametros que estan seteados en el estado.
        // Y finalmente actualizando el estado con el nuevo valor que se quiere agregar.
        
        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(type === "categorias"){  // Si el tipo de variable a setear es categorias...
            for (const [key, value] of Object.entries(filtros)) {   // Iteramos sobre el objeto filtros
                if(key === "limite_inferior"){                      // Asiganmos 0 a query en limite inferior para que siempre
                    query = query + `&${key}=0`;                    // veamos la primer página al cambiar de temas
                } else if(key === "id_categoria"){    // Si estamos en la iteracion id_categoria
                    let categoriesArray = filtros.id_categoria; //Guardamos acá para evitar el delay al setear filtros a un [].
                    //Si hay categorias, no traemos subcategorias y viceversa.
                    if(isChildren === 1) {
                        if(lastClick === "categoria") {
                            setFiltros((prev)=>({...prev, id_categoria: []}));
                            categoriesArray = [];
                        }
                        setLastClick("subcategoria");
                        setSubcategorias(prev => [...prev, data]);
                    } else if(isChildren === 0) {
                        if(lastClick === "subcategoria") {
                            setFiltros((prev)=>({...prev, id_categoria: []}));
                            setSubcategorias([]);
                            categoriesArray = [];
                        }
                        setLastClick("categoria");
                    }
                    if(data && categoriesArray.length > 0){
                        query = query + `&${key}=${categoriesArray.join(',')},${data}&eshija=${isChildren}`; //Si ya hay categorias en el array, los juntamos para realizar la búsqueda
                        setFiltros((prev)=>({...prev, limite_inferior: 0, id_categoria: [...prev.id_categoria, data]})); // Guardamos el nuevo estado con el nuevo valor id_categoria seteado.
                    } else if(data && categoriesArray.length === 0){
                        query = query + `&${key}=${data}&eshija=${isChildren}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, id_categoria: [data]}));
                    }
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){  // Si no, la iteracion actual tiene algo seteado
                        query = query + `&${key}=${value}`;                                                // Lo agregamos tambien
                    }
                }
            }
        }

        if(type === "materiales"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "id_material"){
                    if(data && filtros.id_material.length > 0){
                        query = query + `&${key}=${filtros.id_material.join(',')},${data}`; 
                        setFiltros((prev)=>({...prev, limite_inferior: 0, id_material: [...prev.id_material, data]})); 
                    } else if(data && filtros.id_material.length === 0){
                        query = query + `&${key}=${data}`;
                        setFiltros((prev)=>({...prev, limite_inferior: 0, id_material: [data]}));
                    }
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        if(type === "search"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "filtrotexto"){
                    query = query + `&${key}=${data}`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, filtrotexto: data}));
        }

        if(type === "oferta"){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "oferta"){
                    query = query + `&${key}=1`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, oferta: "1"}));
        }

        // Los filtros campo y orden representan el select de "Ordenar por". Campo puede valer "precio", "titulo" y "antiguedad". Orden puede valer "ASC" o "DESC".
        // Para este caso, el parametro "data" llega como, por ejemplo: precio_ASC.

        if(type === "orden"){

            if(data === "default"){
                setFiltros((prev)=>({...prev, campo: null, orden: null}));
                return;
            }

            let [campo, orden] = data.split("_");

            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "campo"){
                    query = query + `&campo=${campo}`;

                } else if(key === "orden"){
                    query = query + `&orden=${orden}`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, campo: campo, orden: orden}));
        }

        if(lastClick === "subcategoria" && type !== "categorias") {
            query = query + `&eshija=1`;
        }

        fetchData(query);
    }

    const handlePrice = function(e){

        // Mismo que veniamos haciendo pero con precios. Hay que hacer 4 casos porque puede haber
        // Precio hasta, pero no desde
        // Precio desde, pero no hasta
        // Precio desde y hasta
        // Ninguno
        // Puede ser que se pueda optimizar pero yo ya me fui jaja

        e.preventDefault();

        setLoading(true);

        let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(lastClick === "subcategoria") {
            query = query + `&eshija=1`;
        }

        if(priceTextInput.desde && !priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "precio_desde"){
                    query = query + `&${key}=${priceTextInput.desde}`;
                } else {
                    if(((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) && key !== "precio_hasta"){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: priceTextInput.desde, precio_hasta: null}));
        }

        else if(!priceTextInput.desde && priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else if(key === "precio_hasta"){
                    query = query + `&${key}=${priceTextInput.hasta}`;
                } else {
                    if(((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) && key !== "precio_desde"){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: null, precio_hasta: priceTextInput.hasta}));
        }

        else if(priceTextInput.desde && priceTextInput.hasta){
            for (const [key, value] of Object.entries(filtros)) {
                if(key === "limite_inferior"){
                    query = query + `&${key}=0`;
                }

                if(key === "precio_desde"){
                    query = query + `&precio_desde=${priceTextInput.desde}`;
                }
                
                if(key === "precio_hasta"){
                    query = query + `&precio_hasta=${priceTextInput.hasta}`;
                } 

                if(key !== "precio_desde" && key !== "precio_hasta"){
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)){
                        query = query + `&${key}=${value}`;
                    }
                }
            }
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: priceTextInput.desde, precio_hasta: priceTextInput.hasta}));
        }

        else if(!priceTextInput.desde && !priceTextInput.hasta){
            setFiltros((prev)=>({...prev, limite_inferior: 0, precio_desde: null, precio_hasta: null}));
            return;
        }

        fetchData(query);
    }

    const fetchNewPage = async function(){
        
        // Es llamada cuando se clickea el boton "Ver mas" de las tarjetas.
        // Usa la misma query anterior, pero cambia la variable "limite_inferior" (que como vimos, en realidad representa a partir de que santeria vamos a traer nuevos santerias)
        // Y asi busca las próximas 20 santerias. Despues junta este nuevo array con el array del estado santerias.
        
        let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(lastClick === "subcategoria") {
            query = query + `&eshija=1`;
        } 

        for (const [key, value] of Object.entries(filtros)) {
            if(value !== null){
                if (key === "limite_inferior") {
                    query = query + `&${key}=${value + 20}`;
                    setFiltros((prev)=>({...prev, limite_inferior: value + 20}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        let promise;

        try{
            promise = await axios.get(query);
        } catch(e){
            console.log(e);
        }

        if (!promise.data){
            return;
        } else {
            setSanterias([...santerias, ...promise.data]);
        }
        
        setLoading(false);
    }

    const handlePagination = async function(page) {
        setPaginaMayor([...paginaMayor, page]);
        let numeroMayor = Math.max(...paginaMayor);

        let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(lastClick === "subcategoria") {
            query = query + `&eshija=1`;
        } 

        for (const [key, value] of Object.entries(filtros)) {
            if(value !== null){
                if(key === "limite_inferior" && page > numeroMayor){
                    query = query + `&${key}=${value + 20}`;
                    setFiltros((prev)=>({...prev, limite_inferior: value + 20}));
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        let promise;
        try{
            if (page > numeroMayor) {
                promise = await axios.get(query);
            } else {
                setPaginaActual(page);
                window.scrollTo({ top: 0, behavior: 'smooth' });
            }
        } catch(e){
            console.log(e);
        }

        if (promise?.data){
            setSanterias([...santerias, ...promise.data]);
            setPaginaActual(page);
        } else {
            return;
        }

        window.scrollTo({ top: 0, behavior: 'smooth' });
        setLoading(false);
    }

    const formatActiveFilters = function(search){
        // Maneja las burbujitas que aparecen cuando hay un filtro seleccionado.
        let bubbles = [];

        if(filtros.id_categoria.length > 0){
            //Si hay subcategorias usamos ese array para crear las burbujas, sino usamos el de categorias.
            if(subcategorias.length > 0) {
                subcategorias.forEach(elem => {
                    const themeInSubCategory = categorias?.categorias?.map(subTema => {
                        const hijaEncontrada = subTema.hijas.find(hija => hija.idhija === elem);
                        if (hijaEncontrada) {
                            return hijaEncontrada.categoriahija;
                        }
                    }).filter(nombre => nombre !== undefined).join(', ');
                    const themeName = `Subcategoría: ${themeInSubCategory}`;
                    
                    // Verifica si el elemento ya existe en bubbles
                    if (!bubbles.some(item => item.value === themeName)) {
                        bubbles.push({ value: themeName, key: "id_categoria" });
                    }
                });
            } else {
                filtros.id_categoria.forEach(elem => {
                    const themeInCategory = categorias?.categorias?.find(tema => tema.id === elem)?.categoria;
                    const themeName = `Categoría: ${themeInCategory}`;

                    if (!bubbles.some(item => item.value === themeName)) {
                        bubbles.push({ value: themeName, key: "id_categoria" });
                    }
                });
            }
        }
        if(filtros.precio_desde){
            bubbles.push({value: `Precio Min: $${filtros.precio_desde}`, key: "precio_desde"})
        }
        if(filtros.precio_hasta){
            bubbles.push({value: `Precio Max: $${filtros.precio_hasta}`, key: "precio_hasta"})
        }
        if(filtros.id_material){
            filtros.id_material.forEach(elem => {

                const collectionInCategory = categorias?.materiales?.find(material => material.id === elem)?.material;
                const collectionName = `Material: ${collectionInCategory}`;

                // Verifica si el elemento ya existe en bubbles
                if (!bubbles.some(item => item.value === collectionName)) {
                    bubbles.push({ value: collectionName, key: "id_material" });
                }
            });
        }
        if(filtros.filtrotexto) {
            if(search) {
                bubbles.push({value: `Búsqueda: ${search}`, key: "filtrotexto"});
            } else {
                bubbles.push({value: `Búsqueda: ${filtros.filtrotexto}`, key: "filtrotexto"});
            }
        }
        if(filtros.oferta){
            bubbles.push({value: `Oferta: Si`, key: "oferta"})
        }

        if(bubbles.length && !ordenarHabilitado){
            setOrdenarHabilitado(true);
        }

        return <>
        {
            bubbles.length > 1 && !isMobile &&
            <span className={s.bubblesCleanButton} onClick={() => cleanActiveFilters()}>
                Limpiar filtros
            </span>
        }
        {
            bubbles.map((bubble, i)=>{
                return (
                        <div>
                            {
                                !isMobile ? 
                                    <div key={i} className={s.bubble}>
                                        <p>{capitalizeWords(bubble.value)}</p>
                                        <Close sx={{ fontSize: 25, color: "#1c1d3e", cursor: "pointer"}} 
                                            onClick={()=>{handleBubbleClose(bubble.key, bubbles.length, bubble.value);}}/>
                                    </div>
                                    :
                                    <div key={i} className={s.bubble} onClick={()=>{handleBubbleClose(bubble.key, bubbles.length, bubble.value);}}>
                                        <p>
                                            {
                                                bubble.key === "oferta" ?
                                                "Oferta"
                                                :
                                                capitalizeWords(bubble.value.substring(bubble.value.indexOf(":") + 2))
                                            }
                                        </p>
                                    </div>
                            }
                        </div>
                )
            })
        }
        </>

    }

    const handleBubbleClose = function(targetKey, bubblesLength, targetValue) {
        // Al cerrar una burbuja, generamos el nuevo string query sin ese parametro y seteamos el estado de filtros sacando ese parametro.
        let cleanValue;
        setLoading(true);

        //Modificamos el string para quitarle el "Categoria:" o "Material:".
        if (targetValue.includes("Categoría")) {
            cleanValue = categorias?.categorias?.find(elem => elem.categoria === targetValue.substring(targetValue.indexOf(":") + 2))?.id;
        } else if (targetKey === "id_material") {
            cleanValue = categorias?.materiales?.find(elem => elem.material === targetValue.substring(targetValue.indexOf(":") + 2))?.id;
        } else if (targetValue.includes("Subcategoría")) {
            cleanValue = categorias?.categorias?.map(subTema => {
                const hijaEncontrada = subTema.hijas.find(hija => hija.categoriahija ===  targetValue.substring(targetValue.indexOf(":") + 2));
                if (hijaEncontrada) {
                    return hijaEncontrada.idhija;
                }
            }).filter(nombre => nombre !== undefined).join(', ');

            //Actualizamos subcategorias.
            const newSubcategories = subcategorias.filter(elem => elem !== cleanValue);
            setSubcategorias(newSubcategories);
        } else {
            cleanValue = targetValue.substring(targetValue.indexOf(":") + 2);
        }

        let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}`;

        if(lastClick === "subcategoria") {
            query = query + `&eshija=1`;
        } 

        for (const [key, value] of Object.entries(filtros)) {
            if(value !== null){
                if(key === targetKey && (Array.isArray(value) && value.length > 0)){  //Si el valor es un array ingresamos para eliminar el elemento que se quiere eliminar
                    let deleteValue = filtros[targetKey].filter(elem => elem !== cleanValue);
                    query = query + `&${key}=${deleteValue.join(',')}`;
                    setFiltros((prev)=>({...prev, limite_inferior: 0, [targetKey]: deleteValue}));
                } else if(key === targetKey && !Array.isArray(value)){
                    setFiltros((prev)=>({...prev, [targetKey]: null}));
                } else if (key === "limite_inferior"){
                    query = query + `&${key}=0`;
                } else {
                    if((Array.isArray(value) && value.length > 0) || (!Array.isArray(value) && value !== null)) {
                        query = query + `&${key}=${value}`;
                    }
                }
            }
        }

        // Si se cerraron todas las burbujas, no hay nada que filtrar y nos traemos todas las santerias.

        if(bubblesLength === 1){
            query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}`;

            setOrdenarHabilitado(false)
        }

        if (targetKey === "oferta") { //Cambiamos el estado del checkbox de oferta en mobile para desmarcarlo
            setIsChecked(!isChecked);
        }
        
        fetchData(query);
    }

    const cleanActiveFilters = function() {
        let query = `${process.env.REACT_APP_URL_API}servicios/getsanteria.php?token=${token}&limite_inferior=${filtros.limite_inferior}&limite_superior=${filtros.limite_superior}`;

        for (const [key, value] of Object.entries(filtros)) {
            if (typeof value === "string") {
                setFiltros((prev)=>({...prev, [key]: null}));
            }
            if ((Array.isArray(value) && value.length > 0)) {
                setFiltros((prev)=>({...prev, [key]: []}));
            }
        }

        setOrdenarHabilitado(false);
        fetchData(query);
    }

    const handleInputFormat = function(search, fn) {
        // Elimina caracteres especiales y números del valor del input
        const cleanedValue = search.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/[^a-zA-Z\s]/g, '');
        fn(cleanedValue);
    }

    const modalFiltrosControl = function (bool) {
        setMenuFiltros(bool);
    }

    const handleCheckbox = () => {
        setIsChecked(!isChecked);
        if (!isChecked) {
            handleCategorias("oferta");
        } else {
            handleBubbleClose("oferta", "", "Oferta: Si");
        }
    }

    return (
        <>
            {/*  Modal filtros en mobile */}
            {
                isMobile &&
                <>
                    <div className={s.mobileFilterButtons}>
                        <CallToActionOutlineBooks content="Filtros" onClick={() => modalFiltrosControl(true)} />
                        <SelectOutlineOrder content="Ordenar por" onChange={(e)=>{ handleCategorias("orden", e.target.value) }} />
                    </div>

                    {/* Burbujas */}
                    <div className={s.bubblesContainer}>
                        {
                            formatActiveFilters(searchParams.get('search'))
                        }
                    </div>

                    <Modal
                        isOpen={menuFiltros}
                        onRequestClose={() => { modalFiltrosControl(false) }}
                        style={modalStyles}
                        ariaHideApp={false}
                        className="modalFilterContainer"
                        onAfterOpen={handleOpenOverlay}
                        onAfterClose={handleCloseOverlay}
                    >
                        <FiltrosModalRespSanteria modalMenuRespControl={modalFiltrosControl} categorias={categorias} handleCategorias={handleCategorias} 
                            handleInputFormat={handleInputFormat} handlePrice={handlePrice} setPriceTextInput={setPriceTextInput} handleBubbleClose={handleBubbleClose} 
                            isChecked={isChecked} handleCheckbox={handleCheckbox} />
                    </Modal>
                </>
            }
        
        <div className={s.container}>

            {
                !isMobile &&
                <div className={s.column}>
                    <div className={s.columnHeader}>
                        <p>Objetos Religiosos</p><p>/</p>
                    </div>

                    {/* Burbujas */}
                    <div className={s.bubblesContainer}>
                        {
                            formatActiveFilters(searchParams.get('search'))
                        }
                    </div>

                    <div className={s.columnContent}>

                        {/* Select de orden */}
                        { 
                            ordenarHabilitado == true && 
                            <div className={s.selectContainer}>
                                <p>Ordenar por</p>
                                <select onChange={(e)=>{ handleCategorias("orden", e.target.value) }} id="select">
                                    <option value="default">Seleccione</option>
                                    <option value="precio_DESC">Mayor precio</option>
                                    <option value="precio_ASC">Menor precio</option>
                                    <option value="titulo_ASC">A - Z</option>
                                    <option value="titulo_DESC">Z - A</option>
                                    <option value="antiguedad_DESC">Mas reciente</option>
                                    <option value="antiguedad_ASC">Mas antiguo</option>
                                </select>
                            </div>
                        }

                        {/* Filtro de ofertas si existen */}
                        {
                            categorias?.ofertasSanteria?.length > 0 &&
                            <div className={s.ofertas} onClick={() => handleCategorias("oferta")}>
                                <h3>Ofertas</h3>
                            </div>
                        }

                        {/* Lista de categorias */}
                        <div className={s.category}>
                            <h3>Categorías</h3>
                            <ul>
                                {
                                    categorias?.categorias?.length === 0 &&
                                    <LoadingSpinner/>
                                }
                                {
                                    categorias?.categorias?.length > 5 ?
                                    categorias?.categorias?.map((elem, index)=>{
                                        if(index < 5 || verMas.categoria){
                                            return (
                                                <li key={index} onClick={(e)=>{handleCategorias("categorias", elem.id)}}>
                                                    <span style={{fontWeight: "600"}}>{elem.categoria}</span>
                                                    {
                                                        elem.hijas.length > 0 &&
                                                        <ul>
                                                            {elem.hijas.map((elem) => (
                                                                <li key={elem.idhija}
                                                                onClick={(e)=>{
                                                                    e.stopPropagation();
                                                                    handleCategorias("categorias", elem.idhija, 1)
                                                                    }}
                                                                >
                                                                    {elem.categoriahija}
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    }
                                                </li>
                                            )
                                        }
                                    }) :
                                    categorias?.categorias?.map((elem, index)=>{
                                        return <li key={index} onClick={(e)=>{handleCategorias("categorias", elem.id)}}>
                                            <span style={{fontWeight: "600"}}>{elem.categoria}</span>
                                            {
                                                elem.hijas.length > 0 &&
                                                <ul>
                                                    {elem.hijas.map((elem) => (
                                                        <li key={elem.idhija}
                                                        onClick={(e)=>{
                                                            e.stopPropagation();
                                                            handleCategorias("categorias", elem.idhija, 1)
                                                            }}
                                                        >
                                                            {elem.categoriahija}
                                                        </li>
                                                    ))}
                                                </ul>
                                            }
                                        </li>
                                    })
                                }
                                {
                                    (categorias?.categorias?.length > 5 && !verMas.categoria)&&
                                    <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, categoria: true})) }}>Mostrar más</li>
                                }
                            </ul>
                        </div>

                        {/* Lista de materiales */}
                        <div className={s.category}>
                            <h3>Materiales</h3>
                            <ul>
                                {
                                    categorias?.materiales?.length === 0 &&
                                    <LoadingSpinner/>
                                }
                                {
                                    categorias?.materiales?.length > 5 ?
                                    categorias?.materiales?.map((elem, index)=>{
                                        if(index < 5 || verMas.material){
                                            return <li key={index} onClick={(e)=>{handleCategorias("materiales", elem.id)}}>{elem.material}</li>
                                        }
                                    }) :
                                    categorias?.materiales?.map((elem, index)=>{
                                        return <li key={index} onClick={(e)=>{handleCategorias("materiales", elem.id)}}>{elem.material}</li>
                                    })
                                }
                                {
                                    (categorias?.materiales?.length > 5 && !verMas.material)&&
                                    <li className={s.mostrarMas} onClick={()=>{ setVerMas(prev => ({...prev, material: true})) }}>Mostrar más</li>
                                }
                            </ul>
                        </div>

                        {/* Inputs de busqueda de precios */}
                        <div className={s.category}>
                            <h3>Precio</h3>
                            <form onSubmit={handlePrice}>
                                <div className={s.precioContainer}>
                                    <input type='number' placeholder='Mínimo' min="0" onChange={(e)=>{setPriceTextInput((prev)=>({...prev, desde: e.target.value}))}}/>
                                    <input type='number' placeholder='Máximo' min="0" onChange={(e)=>{setPriceTextInput((prev)=>({...prev, hasta: e.target.value}))}}/>
                                    <button type='submit'>
                                        <ArrowForwardIos sx={{ fontSize: 20, color: "#1C1B1F" }}/>
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>

                    <div onClick={()=>window.scrollTo({top: 0, left: 0, behavior: "smooth"})} className={s.VolArrib}>
                        <ul>
                            <li>Volver Arriba <ArrowUpward/> </li>
                        </ul>
                    </div>

                    {/* Marca de agua del fondo de la columna */}
                    <div className={s.marcaDeAguaWrapper}>
                        <img alt='Marca de agua' src={logo}/>
                    </div>
                </div>
            }

            {/* Renderizamos tarjetas para las santerias */}
            <div className={s.content}>
                <SectionTitle content="Objetos Religiosos" styles={true} />
                <div className={s.cards}>
                    {
                        isMobile ? 
                        santerias?.map((elem,index)=>{
                            return <Card product={elem} isSanteria={true} key={index} />
                        })
                        :
                        santerias?.slice((paginaActual - 1) * 20, paginaActual * 20).map((elem,index)=>{
                            return <Card product={elem} isSanteria={true} key={index}/>
                        })
                    }
                    {
                        loading ?
                        <div className={isMobile ? s.loadingContainer : ""}>
                            <p className={s.loading}>Buscando...</p>
                        </div>
                        
                        :
                        !santerias?.length &&
                        <div className={isMobile ? s.loadingContainer : ""}>
                            <p className={s.loading}>No hay resultados</p>
                        </div>
                    }
                </div>

                {/* Boton de ver mas si es que hay mas para ver... (mobile)*/}
                {
                    isMobile && santerias?.length !== 0 && santerias?.length % 20 === 0 &&
                    <CallToAction content="Ver más" onClick={()=>{fetchNewPage();}}/>
                }
                
                {/* Paginador (tablet, desktop)*/}
                {
                    !isMobile && santerias?.length !== 0 &&
                    <Stack spacing={2}>
                        <Pagination 
                            count={santerias?.length !== 0 && (santerias?.length % 20 === 0 || paginaActual < Math.max(...paginaMayor)) ? paginaActual + 1 : paginaActual}
                            shape="rounded" 
                            page={paginaActual}
                            onChange={(e, page) => handlePagination(page)}
                            color="primary" 
                            sx={{
                                ".Mui-selected": {
                                    color: "white!important",
                                    backgroundColor: "#4d92cf!important",
                                },
                                ".Mui-selected:hover": {
                                    backgroundColor: "#4d92cf!important",
                                }
                            }}
                        />
                    </Stack>
                }

                <InfoCompras/>
            </div>
        </div>
    </>
    )
}

export default Santeria;