import s from "./CallToAction.module.css";
import React from "react";
import { capitalizeWords } from "../../helpers";
import { Search } from "@mui/icons-material";
import { ArrowForwardIos } from "@mui/icons-material";
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';

// Son botones. El parametro content define el texto y el parametro onClick, la funcion a ejecutar.

export function CallToAction({ content, onClick, detailStyle=false, disabledStyle=false }) {
  return (
    <button className={!detailStyle ? (disabledStyle ? s.disabledButton : s.callToActionBtn) : s.callToActionBtnDetail} onClick={onClick}>
      {content}
    </button>
  );
}

export function CallToActionOutline({ content, onClick, detailStyle=false }) {
  return (
    <button className={!detailStyle ? s.callToActionBtnOutline : s.callToActionBtnOutlineDetail} onClick={onClick}>
      {content}
    </button>
  );
}

export function CallToActionOutlineBooks({ content, onClick }) {
  return (
    <button className={s.callToActionBtnOutlineFiltros} onClick={onClick}>
      <FilterAltOutlinedIcon sx={{ fontSize: 18, color: "#4d92cf" }} /> 
      {content}
    </button>
  );
}

export function SelectOutlineOrder({ content, onChange }) {
  return (
    <div className={s.selectContainer}>
      <select onChange={onChange} id="select">
        <option value="default">{content}</option>
        <option value="precio_DESC">Mayor precio</option>
        <option value="precio_ASC">Menor precio</option>
        <option value="titulo_ASC">A - Z</option>
        <option value="titulo_DESC">Z - A</option>
        <option value="antiguedad_DESC">Mas reciente</option>
        <option value="antiguedad_ASC">Mas antiguo</option>
      </select>
    </div>
  );
}

export function SelectOutlineFilter({ content, onChange, array, tema }) {
  return (
    <div className={s.selectContainerCategorias}>
      <select onChange={onChange} id="select">
        <option value="default">{content}</option>
        {array.map((elem, index) => {
          return (
            <option value={tema ? elem.id_tema : elem.coleccion} key={index}>
              {tema ? elem.tema : elem.coleccion}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export function SelectOutlineFilterSanteriaCategory({ content, fn, array }) {
  return (
    <div className={s.selectContainerCategorias}>
      <select id="selectCategory" onChange={fn}>
        <option value="default">{content}</option>
        {array.map((elem, index) => (
          <React.Fragment key={index}>
            <option value={`id: ${elem.id}`} style={{fontWeight: "bold"}}>
              {capitalizeWords(elem.categoria)}
            </option>
            {elem.hijas?.length > 0 && elem.hijas.map((subcategoria, subIndex) => (
              <option value={`subId: ${subcategoria.idhija}`} key={subIndex}>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{capitalizeWords(subcategoria.categoriahija)}
              </option>
            ))}
          </React.Fragment>
        ))}
      </select>
    </div>
  );
}

export function SelectOutlineFilterSanteriaMaterial({ content, onChange, array }) {
  return (
    <div className={s.selectContainerCategorias}>
      <select onChange={onChange} id="selectMaterial">
        <option value="default">{content}</option>
        {array.map((elem, index) => {
          return (
            <option value={elem.id} key={index} style={{fontWeight: "bold"}}>
              {capitalizeWords(elem.material)}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export function InputOutlineSearch({
  content,
  onSubmit,
  onChange,
  onClick,
  array,
  autor,
}) {
  return (
    <form onSubmit={onSubmit} className={s.inputWrapper}>
      <Search sx={{ fontSize: 12, color: "#4d92cf", paddingLeft: "3px" }} />
      <input
        type="text"
        placeholder={content}
        onChange={onChange}
        required
        pattern=".{3,}"
        title="Ingresar al menos 3 caracteres"
      />
      <ul>
        {array?.length > 5
          ? array?.map((elem, index) => {
              if (index < 5) {
                return (
                  <li
                    key={index}
                    onClick={(e) => {
                      autor
                        ? onClick(e, elem.autor)
                        : onClick("editoriales", elem.editorial);
                    }}
                  >
                    {autor
                      ? capitalizeWords(elem.autor)
                      : capitalizeWords(elem.editorial)}
                  </li>
                );
              }
            })
          : array?.map((elem, index) => {
              return (
                <li
                  key={index}
                  onClick={(e) => {
                    autor
                      ? onClick(e, elem.autor)
                      : onClick("editoriales", elem.editorial);
                  }}
                >
                  {autor
                    ? capitalizeWords(elem.autor)
                    : capitalizeWords(elem.editorial)}
                </li>
              );
            })}
      </ul>
    </form>
  );
}

export function InputOutlinePrice({ onSubmit, onChange }) {
  return (
    <div className={s.category}>
      <h3>Precio</h3>
      <form onSubmit={onSubmit}>
        <div className={s.precioContainer}>
          <input
            type="number"
            placeholder="Mínimo"
            min="0"
            onChange={(e) => {
              onChange((prev) => ({ ...prev, desde: e.target.value }));
            }}
          />
          <input
            type="number"
            placeholder="Máximo"
            min="0"
            onChange={(e) => {
              onChange((prev) => ({ ...prev, hasta: e.target.value }));
            }}
          />
          <button type="submit">
            <ArrowForwardIos sx={{ fontSize: 15, color: "#4d92cf" }} />
          </button>
        </div>
      </form>
    </div>
  );
}

export function CheckboxOutlineFilter({ content, isChecked, handleCheckbox }) {
  return (
    <div className={s.checkboxContainer} onClick={handleCheckbox}>
      <label htmlFor="filter_checkbox">{content}</label>
      <input id="filter_checkbox" type="checkbox" checked={isChecked} onChange={handleCheckbox} />
    </div>
  );
}
