export const GET_TOKEN = "GET_TOKEN";
export const GET_LIBROS = "GET_LIBROS";
export const GET_CATEGORIAS = "GET_CATEGORIAS";
export const GET_LIBRO = "GET_LIBRO";
export const CLEAR_LIBRO = "CLEAR_LIBRO";
export const GET_HOME = "GET_HOME";
export const UPDATE_CART_CANT = "UPDATE_CART_CANT";
export const SET_NAVBAR_POSITION = "SET_NAVBAR_POSITION";
export const GET_SANTERIA_CATEGORIAS = "GET_SANTERIA_CATEGORIAS";
export const GET_SANTERIA = "GET_SANTERIA";
export const CLEAR_SANTERIA = "CLEAR_SANTERIA";