import React, { useEffect, useState } from 'react';
import s from "./FormDireccion.module.css";
import { ArrowBackIos } from '@mui/icons-material';
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useSelector } from 'react-redux';
import useMediaQuery from "../../../hooks/useMediaQuery"

export default function FormDireccion({nextStep, inputs, setInputs, type, newAdress}) {

    // Este componente es un formulario controlado para ingresar una nueva direccion.
    // Puede ser renderizado de dos formas, una con informacion personal del usuario, como en el activeStep 1 y otra SIN informacion del usuario, como en el modal de nueva direccion.

    const [validated, setValidated] = useState(false);
    const [provincias, setProvincias] = useState(null);

    const isTablet = useMediaQuery('(max-width: 1024px)');

    const Navigate = useNavigate();
    const token = useSelector(state => state.token);

    const handleInputChange = function(e){
        setInputs((prev)=>({...prev, [e.target.id]: e.target.value}));
    }

    useEffect(()=>{
        async function fetchData(){
    
            try{
                if(!provincias) {
                    const response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/getprovincias.php?token=${token}`);
                    const data = response.data;
                
                    if (Array.isArray(data)) {
                        setProvincias(data);
                    } else {
                        setProvincias([]);
                    }
                }
            } catch(error){
                console.log(error);
            }
        }

        fetchData();
    },[provincias]);

    useEffect(()=>{
        let aux = true;
        for (const [key, value] of Object.entries(inputs)) {
            if(key === "provincia"){
                if(value === ""){
                    aux = false;
                }
            }
            if((key === "barrio") || (key === "piso") || (key === "alias")){
                continue;
            }
            if(value === "" || !value){
                aux = false;
            }
            let productsCart = [];
            productsCart = localStorage.getItem('order');
            productsCart = JSON.parse(productsCart);
    
            if(!productsCart.length){
                aux = false;
            }
        }
        setValidated(aux);
    },[inputs])

    if(type === "regular"){
        return (
            <form className={s.form} onSubmit={(e)=>{e.preventDefault(); validated && nextStep(); }}>
                <h3>Datos de facturación</h3>
                <div className={s.rows}>
                    <div className={s.inputContainer}>
                        <label htmlFor="nombre">Nombre</label>
                        <input id='nombre' type='text' required size="1" onChange={handleInputChange} value={inputs.nombre}/>
                    </div>
                    <div className={s.inputContainer}>
                        <label htmlFor="apellido">Apellido</label>
                        <input id='apellido' type='text' required size="1" onChange={handleInputChange} value={inputs.apellido}/>
                    </div>
                </div>
                <div className={s.rows}>
                    <div className={s.inputContainer}>
                        <label htmlFor="dni">DNI</label>
                        <input id='dni' type='text' required size="1" onChange={handleInputChange} value={inputs.dni}/>
                    </div>
                    <div className={s.inputContainer}>
                        <label htmlFor="telefono">Teléfono</label>
                        <input id='telefono' type='tel' required size="1" onChange={handleInputChange} value={inputs.telefono}/>
                    </div>
                </div>
                <div className={s.inputContainer}>
                    <label htmlFor="email">Correo electrónico</label>
                    <input id='email' type='email' required size="1" onChange={handleInputChange} value={inputs.email}/>
                </div>
                <p className={s.direccionTitle}>Dirección</p>
                <div className={s.inputContainer2}>
                    <input id="calle" type='text' placeholder='Ingresar Calle' required size="1" onChange={handleInputChange} value={inputs.calle}/>
                </div>
                <div className={s.rows}>
                    <div className={s.inputContainer2}>
                        <input id="altura" type='text' placeholder='Altura' required size="1" onChange={handleInputChange} value={inputs.altura}/>
                    </div>
                    <div className={s.inputContainer2}>
                        <input id="piso" type='text' placeholder='Piso y depto' size="1" onChange={handleInputChange} value={inputs.piso}/>
                    </div>
                    {
                        !isTablet &&
                        <div className={s.inputContainer2}>
                            <input id="localidad" type='text' placeholder='Localidad' required size="1" onChange={handleInputChange} value={inputs.localidad}/>
                        </div>
                    }
                </div>
                {
                    isTablet &&
                    <div className={s.rows}>
                        <div className={s.inputContainer2}>
                            <input id="localidad" type='text' placeholder='Localidad' required size="1" onChange={handleInputChange} value={inputs.localidad}/>
                        </div>
                    </div>
                }
                <div className={s.rows}>
                    <div className={s.inputContainer2}>
                        <select onChange={handleInputChange} id="provincia" size="1" required value={inputs.provincia}>
                            <option value="">Provincia</option>
                            {
                                provincias?.map((provincia, index)=>{
                                    return <option key={index} value={provincia.id_provincia}>{provincia.nombre}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className={s.inputContainer2}>
                        <input id="cp" type='text' placeholder='CP' required size="1" onChange={handleInputChange} value={inputs.cp}/>
                    </div>
                    {
                        !isTablet &&
                        <div className={s.inputContainer2}>
                            <input id="barrio" type='text' placeholder='Barrio'  size="1" onChange={handleInputChange} value={inputs.barrio}/>
                        </div>
                    }
                </div>
                {
                    isTablet &&
                    <div className={s.rows}>
                        <div className={s.inputContainer2}>
                            <input id="barrio" type='text' placeholder='Barrio'  size="1" onChange={handleInputChange} value={inputs.barrio}/>
                        </div>
                    </div>
                }
                <div className={s.rows}>
                    <div className={s.inputContainer2}>
                        <input id="alias" type='textarea' placeholder='Informacion Adicional' size="1" onChange={handleInputChange} value={inputs.alias}/>
                    </div>
                </div>
                <div className={s.btnsContainer}>
                    <div className={s.btnBackContainer} onClick={()=>{Navigate("/")}}>
                        <ArrowBackIos sx={{ fontSize: 16 }}/>
                        <p className={s.btnBack}>Volver al Home</p>
                    </div>
                    <button type='submit' className={validated ? s.btnSubmit : s.btnSubmitDisabled}>Siguiente</button>
                </div>
            </form>
        )
    }

    if(type === "modal"){
        return (
            <form className={s.formModal} onSubmit={(e)=>{e.preventDefault(); validated && newAdress(); }}>
                <p className={s.direccionTitle}>Dirección de envío</p>
                <div className={s.inputContainer2}>
                    <input id="calle" type='text' placeholder='Ingresar Calle' required size="1" onChange={handleInputChange}/>
                </div>
                <div className={s.rows}>
                    <div className={s.inputContainer2}>
                        <input id="altura" type='text' placeholder='Altura' required size="1" onChange={handleInputChange}/>
                    </div>
                    <div className={s.inputContainer2}>
                        <input id="piso" type='text' placeholder='Piso y depto' size="1" onChange={handleInputChange}/>
                    </div>
                    {
                        !isTablet &&
                        <div className={s.inputContainer2}>
                            <input id="localidad" type='text' placeholder='Localidad' required size="1" onChange={handleInputChange}/>
                        </div>
                    }
                </div>
                {
                    isTablet &&
                    <div className={s.rows}>
                        <div className={s.inputContainer2}>
                            <input id="localidad" type='text' placeholder='Localidad' required size="1" onChange={handleInputChange}/>
                        </div>
                    </div>
                }
                <div className={s.rows}>
                    <div className={s.inputContainer2}>
                        <select onChange={handleInputChange} id="provincia" size="1" required>
                            <option value="">Provincia</option>
                            {
                                provincias?.map((provincia, index)=>{
                                    return <option key={index} value={provincia.id_provincia}>{provincia.nombre}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className={s.inputContainer2}>
                        <input id="cp" type='text' placeholder='CP' required size="1" onChange={handleInputChange}/>
                    </div>
                    {
                        !isTablet &&
                        <div className={s.inputContainer2}>
                            <input id="barrio" type='text' placeholder='Barrio'  size="1" onChange={handleInputChange}/>
                        </div>
                    }
                </div>
                {
                    isTablet &&
                    <div className={s.rows}>
                        <div className={s.inputContainer2}>
                            <input id="barrio" type='text' placeholder='Barrio' size="1" onChange={handleInputChange}/>
                        </div>
                    </div>
                }
                <div className={s.rows}>
                    <div className={s.inputContainer2}>
                        <input id="alias" type='textarea' placeholder='Alias' size="1" onChange={handleInputChange}/>
                    </div>
                </div>
                <div className={s.btnsContainerModal}>
                    <button type='submit' className={validated ? s.btnSubmit : s.btnSubmitDisabled}>Siguiente</button>
                </div>
            </form>
        )
    }
}
