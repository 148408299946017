// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import s from "./SwiperCards.module.css";

// import required modules
import { FreeMode, Pagination, Navigation} from "swiper";

import Card from "../Card/Card";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useState, useCallback, useEffect } from "react";
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

export default function App({products, detailPage}) {

  const isMobile = useMediaQuery('(max-width: 768px)');
  const isSmallScreen = useMediaQuery('(max-width: 616px)');

  const [swiperRef, setSwiperRef] = useState(null);
  const [isFirstSlide, setIsFirstSlide] = useState(null);
  const [isLastSlide, setIsLastSlide] = useState(null);

  const handleSlidesAvailability = () => { //Chequeamos si existen más slides para cambiar el color de chevron
    if (swiperRef?.isBeginning) {
      setIsFirstSlide(true);
    } else if (swiperRef?.isBeginning === false) {
      setIsFirstSlide(false);
    }
    if (swiperRef?.isEnd) {
      setIsLastSlide(true);
    } else if (swiperRef?.isEnd === false) {
      setIsLastSlide(false);
    }
  }

  const handlePrevious = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const handleNext = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  useEffect(() => {
    if (!isMobile) {
      handleSlidesAvailability();
    }
  },[swiperRef?.isBeginning, swiperRef?.isEnd]);

  return (
    <div className={detailPage ? s.carouselContainerDetail : s.carouselContainer}>
      {
        !isMobile && products && products.length > 0 &&
        <div>
          <div className={s.swipperButtonsContainer} onClick={handlePrevious }>
            <ArrowBackIosIcon sx={isFirstSlide ? {fontSize: 30, color: "#a9a5a5"} : {fontSize: 30, color: "#282828"}} />
          </div>
        </div>
      }
      <Swiper
        style={{
          "--swiper-pagination-color": "#1c1d3e",
          "--swiper-pagination-bullet-inactive-color": "#949CA9",
          "--swiper-pagination-bullet-inactive-opacity": "1",
          "--swiper-pagination-bullet-size": "16px",
          "--swiper-pagination-bullet-horizontal-gap": "6px"
        }}
        slidesPerView={isSmallScreen ? 2 : isMobile ? 3 : 'auto'}
        spaceBetween={isMobile ? 5 : 10}
        freeMode={false}
        navigation={false}
        modules={[FreeMode, Pagination, Navigation]}
        className={s.swiper}
        onSwiper={setSwiperRef}
        onSlideChange={() => handleSlidesAvailability()}
        pagination={isMobile ? {clickable: true, dynamicBullets: true, dynamicMainBullets: 4} : false}
      >
        {
            products && products.length > 0 ?
            products.map((product,index)=>{
              return <SwiperSlide key={index} className={s.swiperSlide}><Card product={product} index={index} isSanteria={product.id_santeria ? true : false} /></SwiperSlide>
            }) :
            <p className={s.notFound}>No hay resultados</p>
        }
      </Swiper>
      {
        !isMobile && products && products.length > 0 &&
        <div>
          <div className={s.swipperButtonsContainer} onClick={handleNext}>
            <ArrowForwardIosIcon sx={isLastSlide ? {fontSize: 30, color: "#a9a5a5", marginLeft: "6px"} : {fontSize: 30, color: "#282828", marginLeft: "6px"}} />
          </div>
        </div>
      }
    </div>
  );
}
