import React, { useState, useEffect } from 'react';
import s from "./Cart.module.css";

import { Close, ShoppingCart} from '@mui/icons-material';
import CartCard from "../CartCard/CartCard";
import { useNavigate, useLocation } from 'react-router-dom';
import { updateCart, getToken } from '../../redux/actions';
import { useDispatch, useSelector, connect } from 'react-redux';
import { formatNumber } from '../../helpers';
import Modal from 'react-modal';
import axios from 'axios';

Modal.setAppElement(document.getElementById('root'));

const modalStyles = {
    overlay: {
        zIndex: 100,
        background: "#00000060",
        height: "100vh",
        width: "100vw",
        margin: "0",
    }
};

function Cart({modalControl, type, totalEnvio, cp}) {

    //Carrito. Se puede mostrar como carrito para el navbar o como carrito para el checkout con edicion deshabilitada.

    let idBooks;
    let idSanterias;
    let cartBooksLength;
    let cartSanteriasLength;
    const [products, setProducts] = useState([]);
    const [booksFromDB, setBooksFromDB] = useState([]);
    const [santeriasFromDB, setSanteriasFromDB] = useState([]);
    const [total, setTotal] = useState(0);
    const [wrongPriceModal, setWrongPriceModal] = useState(false);

    const Navigate = useNavigate();
    const location = useLocation();
    const dispatch = useDispatch();
    const token = useSelector(state => state.token);

    const getIdProductsFromCart = (cartProducts) => {
        //Obtenemos los Id de los libros y santerias del carrito para hacer la llamada al servicio y comparar precios.
        if(cartProducts) {
            idBooks = cartProducts
                .filter(elem => elem.id_libro)  // Filtramos aquellos elementos que tengan el campo id_libro definido y no sea falsy
                .map(elem => elem.id_libro)     // Extraemos solo el campo id_libro de cada elemento
                .join(',');                      // Convertimos el array resultante en una cadena separada por comas
            cartBooksLength = cartProducts.length;

            idSanterias = cartProducts
                .filter(elem => elem.id_santeria)
                .map(elem => elem.id_santeria)
                .join(',');
            cartSanteriasLength = cartProducts.length;
        }
        return;
    }

    const comparePrices = (productsFromDB, type) => {
        // Recorre products y compara cada objeto con productsFromDB por su ID.
        for (let i = 0; i < products.length; i++) {
            const productFromCart = products[i];

            // Encuentra el objeto correspondiente en productsFromDB según el ID de productFromCart.
            let productFromDB
            if(type === "libros"){
                productFromDB = productsFromDB.find((elem) => (elem.id_libro === productFromCart.id_libro));
            } else {
                productFromDB = productsFromDB.find((elem) => (elem.id_santeria === productFromCart.id_santeria));
            }

            if (productFromDB && productFromCart.preciodescuento !== productFromDB.preciodescuento) {
                // Reemplaza el valor de precio en products con el valor de precio en productsFromDB.
                productFromCart.precio = productFromDB.precio;
                productFromCart.preciodescuento = productFromDB.preciodescuento;
                setWrongPriceModal(true);
            }
        }
    }

    useEffect(() => {
        if (!token) {
            dispatch(getToken());
        }

        if (localStorage.getItem('order')) {
            calculateTotal();
        }
    }, []);

    useEffect(()=>{ // Nos traemos la orden del localStorage, si existe

        //Consultamos los productos del carrito en la base de datos para ver si el precio no se ha actualizado
        const getProductsFromDB = async (idProducts, fn, url) => {
            if(token && idProducts) {
                try {
                    const response = await axios.get(`${process.env.REACT_APP_URL_API}servicios/${url}`);
                    fn(response.data);
                } catch (error) {
                    console.log(error);
                }
            }
        }

        getProductsFromDB(idBooks, setBooksFromDB, `getlibros.php?token=${token}&cadenalibros=${idBooks}&limite_inferior=0&limite_superior=${cartBooksLength}`);
        getProductsFromDB(idSanterias, setSanteriasFromDB, `getsanteria.php?token=${token}&cadenasanteria=${idSanterias}&limite_inferior=0&limite_superior=${cartSanteriasLength}`);
        comparePrices(booksFromDB, "libros");
        comparePrices(santeriasFromDB, "santerias");

        if(wrongPriceModal) {
            calculateNewTotal();
        }

    },[booksFromDB, santeriasFromDB, idSanterias, wrongPriceModal]);

    const calculateTotal = function(){
        let productsCart = [];
        productsCart = localStorage.getItem(location.pathname === "/confirmacionMP" ? "products" : "order");
        getIdProductsFromCart(JSON.parse(productsCart));
        productsCart = JSON.parse(productsCart);

        let resultado = 0;

        if(!productsCart) {
            return;
        } else {
            for(let i = 0; i < productsCart.length; i++){
                resultado = resultado + (Number(productsCart[i].preciodescuento) * Number(productsCart[i].cant));
            }
        }

        setProducts(productsCart)
        setTotal(resultado)
        dispatch(updateCart());
    }

    const calculateNewTotal = () => {
        let resultado = 0;

        for(let i = 0; i < products.length; i++){
            resultado = resultado + (Number(products[i].preciodescuento) * Number(products[i].cant));
        }

        setTotal(resultado)
        dispatch(updateCart());
        localStorage.setItem('order', JSON.stringify(products));
    }

    const deleteItem = function(product_id){ // Buscamos el item por id y lo borramos del localStorage
        let productsCart = [];
        productsCart = localStorage.getItem('order');
        productsCart = JSON.parse(productsCart);
        productsCart = productsCart.filter((product) => product.id_libro !== product_id && product.id_santeria !== product_id);
        localStorage.setItem('order', JSON.stringify(productsCart))

        calculateTotal();
    }

    const handleCheckout = function(){
        if(products.length > 0){
            Navigate("/Checkout");
            modalControl(false);
        }
    }

    if(type==="navbar"){
        return (
            <div className={s.container}>
                <div className={s.header}>
                    <Close sx={{ fontSize: 28, color: "#FFFFFF", cursor: "pointer"}} onClick={()=>{modalControl(false)}}/>
                    <div className={s.MiCartResp}>
                        <ShoppingCart sx={{ fontSize: 20, color: "#FFFFFF"}}/>
                        <p>Mi Carrito</p>
                    </div>
                    <div/>
                </div>
                <div className={s.cards}>
                    {
                        products?.map((p, i)=>{
                            return <CartCard product={p} key={i} deleteItem={deleteItem} calculateTotal={calculateTotal} modalControl={modalControl} disableCant={false}/>
                        })
                    }
                </div>
                <div className={s.resumen}>
                    <div className={s.totalContainer}>
                        <p>Subtotal:</p>
                        <p>{formatNumber(total)}</p>
                    </div>
                    <div className={s.btnsContainer}>
                        <button className={s.callToActionBtn} onClick={()=>{handleCheckout();}}>Proceder al Pago</button>
                        <button className={s.callToActionBtnOutline} onClick={()=>{modalControl(false);}}>Seguir Comprando</button>
                    </div>
                </div>

                {/* Modal de cambio de precios */}
                <Modal
                    isOpen={wrongPriceModal}
                    onRequestClose={()=>{}}
                    style={modalStyles}
                    ariaHideApp={false}
                    className={s.modalWrongPriceContainer}
                >
                    <div className={s.modalContainer}>
                        <div className={s.modalWrongPrice}>
                            <h4>Los precios se han actualizado</h4>
                            <p>Revise el carrito para ver los precios</p>
                            <button className={s.callToActionBtn} onClick={() => setWrongPriceModal(false)}>
                                Entiendo
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    if(type ==="checkout"){
        return (
            <div className={s.containerCheckout}>
                <div className={s.cardsCheckout}>
                    {
                        products?.map((p, i)=>{
                            return <CartCard product={p} key={i} deleteItem={deleteItem} calculateTotal={calculateTotal} modalControl={modalControl} disableCant={true}/>
                        })
                    }
                </div>
                {
                    totalEnvio ? 
                    <div className={s.resumenCheckout}>
                        <div className={s.totalContainerCheckout}>
                            <p className={s.cp}>Envío a CP {cp}:</p>
                            <p className={s.cp}>{formatNumber(totalEnvio)}</p>
                        </div>
                        <div className={s.totalContainerCheckout}>
                            <p className={s.total}>Total con envío:</p>
                            <p className={s.total}>{formatNumber(Number(total) + Number(totalEnvio))}</p>
                        </div>
                    </div> :
                    <div className={s.resumenCheckout}>
                        <div className={s.totalContainerCheckout}>
                            <p className={s.total}>Subtotal:</p>
                            <p className={s.total}>{formatNumber(total)}</p>
                        </div>
                    </div>
                }
                {/* Modal de cambio de precios */}
                <Modal
                    isOpen={wrongPriceModal}
                    onRequestClose={()=>{}}
                    style={modalStyles}
                    ariaHideApp={false}
                    className={s.modalWrongPriceContainer}
                >
                    <div className={s.modalContainer}>
                        <div className={s.modalWrongPrice}>
                            <h4>Los precios se han actualizado</h4>
                            <p>Revise el carrito para ver los precios</p>
                            <button className={s.callToActionBtn} onClick={() => setWrongPriceModal(false)}>
                                Entiendo
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }

    if(type ==="checkoutEditable"){
        return (
            <div className={s.containerCheckout}>
                <div className={s.cardsCheckout}>
                    {
                        products?.map((p, i)=>{
                            return <CartCard product={p} key={i} deleteItem={deleteItem} calculateTotal={calculateTotal} modalControl={modalControl} disableCant={false}/>
                        })
                    }
                </div>
                {
                    totalEnvio ? 
                    <div className={s.resumenCheckout}>
                        <div className={s.totalContainerCheckout}>
                            <p className={s.cp}>Envío a CP {cp}:</p>
                            <p className={s.cp}>{formatNumber(totalEnvio)}</p>
                        </div>
                        <div className={s.totalContainerCheckout}>
                            <p className={s.total}>Total con envío:</p>
                            <p className={s.total}>{formatNumber(Number(total) + Number(totalEnvio))}</p>
                        </div>
                    </div> :
                    <div className={s.resumenCheckout}>
                        <div className={s.totalContainerCheckout}>
                            <p className={s.total}>Subtotal:</p>
                            <p className={s.total}>{formatNumber(total)}</p>
                        </div>
                    </div>
                }
                {/* Modal de cambio de precios */}
                <Modal
                    isOpen={wrongPriceModal}
                    onRequestClose={()=>{}}
                    style={modalStyles}
                    ariaHideApp={false}
                    className={s.modalWrongPriceContainer}
                >
                    <div className={s.modalContainer}>
                        <div className={s.modalWrongPrice}>
                            <h4>Los precios se han actualizado</h4>
                            <p>Revise el carrito para ver los precios</p>
                            <button className={s.callToActionBtn} onClick={() => setWrongPriceModal(false)}>
                                Entiendo
                            </button>
                        </div>
                    </div>
                </Modal>
            </div>
        )
    }
}

export default connect(null, { updateCart })(Cart);